import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../constants";
import { HttpRequest, RequestType } from "../../plugins/httpRequest";
import { User } from "../../interfaces/user";

export const login = createAsyncThunk(
  "auth",
  async ({ email, password }: { email: string; password: string }) => {
    const response = await fetch(
      `${API_URL}Auth/Login?email=${email}&password=${password}`,
      {
        method: "POST",
      }
    );
    const result = await response.json();
    return result;
  }
);

export const getCurrentUser = createAsyncThunk("currenUser", async () => {
  const response = await HttpRequest<null, User>({
    url: "Client/Info",
    method: RequestType.GET,
  });
  return response;
});

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async ({ email }: { email: string }) => {
    const response = await fetch(
      `${API_URL}Auth/ForgotPassword?email=${email}`,
      {
        method: "POST",
      }
    );
    const result = await response.json();
    return result;
  }
);

export const changePassowrd = createAsyncThunk(
  "changePassowrd",
  async ({
    email,
    password,
    secretKey,
  }: {
    email: string;
    password: string;
    secretKey: string;
  }) => {
    const response = await fetch(
      `${API_URL}Auth/ChangePassword?email=${email}&password=${password}&secretKey=${secretKey}`,
      {
        method: "POST",
      }
    );
    const result = await response.json();
    return result;
  }
);
