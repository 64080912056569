import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import loginPageTR from "./login-page/tr.json";
import loginPageUS from "./login-page/us.json";
import loginPageFR from "./login-page/fr.json";

import layoutTR from "./layout/tr.json";
import layoutUS from "./layout/us.json";
import layoutFR from "./layout/fr.json";

import dashboardTR from "./dashboard/tr.json";
import dashboardUS from "./dashboard/us.json";
import dashboardFR from "./dashboard/fr.json";

import cardsTR from "./cards/tr.json";
import cardsUS from "./cards/us.json";
import cardsFR from "./cards/fr.json";

const resources = {
  tr: {
    cards: cardsTR,
    dashboard: dashboardTR,
    layout: layoutTR,
    loginPage: loginPageTR,
  },
  us: {
    cards: cardsUS,
    dashboard: dashboardUS,
    layout: layoutUS,
    loginPage: loginPageUS,
  },
  fr:{
    cards: cardsFR,
    dashboard: dashboardFR,
    layout: layoutFR,
    loginPage: loginPageFR,
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "tr",
    debug: false,
    fallbackLng: "tr",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
