export const getTitleSize = (title: string) => {
  const length = title.length;
  if (length <= 25) {
    return 40;
  } else if (length <= 50) {
    return 32;
  } else if (length <= 100) {
    return 24;
  } else if (length <= 200) {
    return 20;
  }
  return 16;
};

export const numberFormat = (n: number) => {
  return new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 0,
  })
    .format(n)
    .replace('₺', '');
};
