import { FormControl, Autocomplete, TextField } from "@mui/material";

const timezones = [
  { label: "(GMT-11:00) Midway Island, Samoa", offset: -11 },
  { label: "(GMT-10:00) Hawaii", offset: -10 },
  { label: "(GMT-9:00) Alaska", offset: -9 },
  { label: "(GMT-8:00) Tijuana", offset: -8 },
  { label: "(GMT-8:00) Pacific Time", offset: -8 },
  { label: "(GMT-7:00) Mountain Time", offset: -7 },
  { label: "(GMT-7:00) Dawson, Yukon", offset: -7 },
  { label: "(GMT-7:00) Arizona", offset: -7 },
  { label: "(GMT-6:00) Chihuahua, La Paz, Mazatlan", offset: -6 },
  { label: "(GMT-6:00) Central Time", offset: -6 },
  { label: "(GMT-6:00) Saskatchewan", offset: -6 },
  { label: "(GMT-6:00) Central America", offset: -6 },
  { label: "(GMT-5:00) Guadalajara, Mexico City, Monterrey", offset: -5 },
  { label: "(GMT-5:00) Eastern Time", offset: -5 },
  { label: "(GMT-5:00) Bogota, Lima, Quito", offset: -5 },
  { label: "(GMT-5:00) Pittsburgh", offset: -5 },
  { label: "(GMT-4:00) Caracas, La Paz", offset: -4 },
  { label: "(GMT-3:30) Newfoundland and Labrador", offset: -3.5 },
  { label: "(GMT-3:00) Santiago", offset: -3 },
  { label: "(GMT-3:00) Brasilia", offset: -3 },
  { label: "(GMT-3:00) Montevideo", offset: -3 },
  { label: "(GMT-3:00) Buenos Aires, Georgetown", offset: -3 },
  { label: "(GMT-3:00) Greenland", offset: -3 },
  { label: "(GMT-1:00) Azores", offset: -1 },
  { label: "(GMT-1:00) Cape Verde Islands", offset: -1 },
  { label: "(GMT+0:00) UTC", offset: 0 },
  { label: "(GMT+0:00) Edinburgh, London", offset: 0 },
  { label: "(GMT+0:00) Dublin", offset: 0 },
  { label: "(GMT+0:00) Lisbon", offset: 0 },
  { label: "(GMT+0:00) Casablanca, Monrovia", offset: 0 },
  { label: "(GMT+0:00) Canary Islands", offset: 0 },
  {
    label: "(GMT+1:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    offset: 1,
  },
  { label: "(GMT+1:00) Sarajevo, Skopje, Warsaw, Zagreb", offset: 1 },
  { label: "(GMT+1:00) Brussels, Copenhagen, Madrid, Paris", offset: 1 },
  {
    label: "(GMT+1:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    offset: 1,
  },
  { label: "(GMT+1:00) West Central Africa", offset: 1 },
  { label: "(GMT+1:00) Frankfurt", offset: 1 },
  { label: "(GMT+2:00) Bucharest", offset: 2 },
  { label: "(GMT+2:00) Cairo", offset: 2 },
  {
    label: "(GMT+2:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    offset: 2,
  },
  { label: "(GMT+2:00) Athens", offset: 2 },
  { label: "(GMT+2:00) Jerusalem", offset: 2 },
  { label: "(GMT+2:00) Harare, Pretoria", offset: 2 },
  {
    label: "(GMT+3:00) Istanbul, Minsk, Moscow, St. Petersburg, Volgograd",
    offset: 3,
  },
  { label: "(GMT+3:00) Kuwait, Riyadh", offset: 3 },
  { label: "(GMT+3:00) Nairobi", offset: 3 },
  { label: "(GMT+3:00) Baghdad", offset: 3 },
  { label: "(GMT+3:30) Tehran", offset: 3.5 },
  { label: "(GMT+4:00) Abu Dhabi, Muscat", offset: 4 },
  { label: "(GMT+4:00) Baku, Tbilisi, Yerevan", offset: 4 },
  { label: "(GMT+4:30) Kabul", offset: 4.5 },
  { label: "(GMT+5:00) Ekaterinburg", offset: 5 },
  { label: "(GMT+5:00) Islamabad, Karachi, Tashkent", offset: 5 },
  { label: "(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi", offset: 5.5 },
  { label: "(GMT+5:30) Sri Jayawardenepura", offset: 5.5 },
  { label: "(GMT+5:45) Kathmandu", offset: 5.75 },
  { label: "(GMT+6:00) Astana, Dhaka", offset: 6 },
  { label: "(GMT+6:00) Almaty, Novosibirsk", offset: 6 },
  { label: "(GMT+6:30) Yangon Rangoon", offset: 6.5 },
  { label: "(GMT+7:00) Bangkok, Hanoi, Jakarta", offset: 7 },
  { label: "(GMT+7:00) Krasnoyarsk", offset: 7 },
  { label: "(GMT+8:00) Beijing, Chongqing, Hong Kong SAR, Urumqi", offset: 8 },
  { label: "(GMT+8:00) Kuala Lumpur, Singapore", offset: 8 },
  { label: "(GMT+8:00) Taipei", offset: 8 },
  { label: "(GMT+8:00) Perth", offset: 8 },
  { label: "(GMT+8:00) Irkutsk, Ulaanbaatar", offset: 8 },
  { label: "(GMT+9:00) Seoul", offset: 9 },
  { label: "(GMT+9:00) Osaka, Sapporo, Tokyo", offset: 9 },
  { label: "(GMT+9:00) Yakutsk", offset: 9 },
  { label: "(GMT+9:30) Darwin", offset: 9.5 },
  { label: "(GMT+10:00) Brisbane", offset: 10 },
  { label: "(GMT+10:00) Vladivostok", offset: 10 },
  { label: "(GMT+10:00) Guam, Port Moresby", offset: 10 },
  { label: "(GMT+10:30) Adelaide", offset: 10.5 },
  { label: "(GMT+11:00) Canberra, Melbourne, Sydney", offset: 11 },
  { label: "(GMT+11:00) Hobart", offset: 11 },
  { label: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia", offset: 11 },
  { label: "(GMT+12:00) Kamchatka, Marshall Islands", offset: 12 },
  { label: "(GMT+12:00) Fiji Islands", offset: 12 },
  { label: "(GMT+13:00) Auckland, Wellington", offset: 13 },
  { label: "(GMT+13:00) Nuku'alofa", offset: 13 },
];

function TimezoneSelect({ selectedTimezone, setSelectedTimezone }: any) {
  const handleTimezoneChange = (event: any, newValue: any) => {
    setSelectedTimezone(newValue?.offset);
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <Autocomplete
        disablePortal
        options={timezones}
        value={selectedTimezone?.offset}
        onChange={handleTimezoneChange}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} label="Select Timezone" />
        )}
      />
    </FormControl>
  );
}

export default TimezoneSelect;
