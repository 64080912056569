import React, { useRef } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

type CropperModalProps = {
  open: boolean;
  onClose: any;
  imageSrc: any;
  setCroppedImgSrc: any;
};

const CropperModal: React.FC<CropperModalProps> = ({
  open,
  onClose,
  imageSrc,
  setCroppedImgSrc,
}) => {
  const cropperRef = useRef() as any;

  function handleCropChange() {
    const croppedImgData = cropperRef.current.cropper
      .getCroppedCanvas({
        width: 600,
        height: 800,
      })
      .toDataURL();

    setCroppedImgSrc(croppedImgData);
  }

  const handleSave = () => {
    handleCropChange();
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "648px",
          height: "100vh",
          bgcolor: "background.paper",
          padding: "0 24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          borderRadius: "8px",
        }}
      >
        <Cropper
          style={{ maxWidth: "600px", height: "800px" }}
          ref={cropperRef}
          src={imageSrc && URL.createObjectURL(imageSrc)}
          aspectRatio={6 / 8}
          // cropend={handleSave}
        />

        <Button sx={{ mt: "12px", width: "100%" }} onClick={handleSave}>
          <Typography variant="buttonText">Save</Typography>
        </Button>
      </Box>
    </Modal>
  );
};

export default CropperModal;
