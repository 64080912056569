import { PhoneWrapper, IconWrapper, IconSeparator } from "./styles";
import AppleIcon from "../../../assets/dashboard/AppleIcon";
import AndroidIcon from "../../../assets/dashboard/AndroidIcon";

interface PhoneStatsProps {
  appleStats?: number;
  androidStats?: number;
}

const PhoneStats: React.FC<PhoneStatsProps> = ({
  appleStats,
  androidStats,
}) => {
  return (
    <PhoneWrapper>
      <IconWrapper>
        <AppleIcon />: {appleStats}
      </IconWrapper>

      <IconSeparator></IconSeparator>

      <IconWrapper>
        <AndroidIcon />: {androidStats}
      </IconWrapper>
    </PhoneWrapper>
  );
};

export default PhoneStats;
