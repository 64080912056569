import { Box, styled } from "@mui/material";

export const Container = styled(Box)`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
`;

export const VersionBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 52px;
`;

export const Left = styled(Box)`
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Right = styled(Box)`
  width: 85%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Version = styled(Box)`
  font-weight: bold;
  font-size: 16px;
`;

export const Date = styled(Box)`
  margin-bottom: 14px;
  font-size: 12px;
  color: #454c55;
`;

export const Title = styled(Box)`
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

export const Description = styled(Box)`
  margin-bottom: 26px;
  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 16px;
  }
`;

export const TimleLine = styled(Box)`
  background-color: #454c55;
  width: 2px;
  height: 100%;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #454c55;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #454c55;
  }
`;

export const DetailBox = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    gap: 10px;
  }
`;

export const Prefix = styled(Box)<{ type: string }>`
  width: 140px;
  height: 36px;
  border-radius: 8px;
  background-color: ${({ type }) => {
    switch (type) {
      case "Added":
        return "#006128";
      case "Updated":
        return "#a58b00";
      case "Edited":
        return "#0065be";
      case "Deleted":
        return "#bb0000";
      case "Fixed":
        return "#000000";
      case "Security":
        return "#009ce2";
      case "Changed":
        return "#5a00cc";
      case "Renamed":
        return "#FFD600";
      default:
        return "#000";
    }
  }};
  display: flex;
  align-items: center;

  color: #fff;
  font-size: 14px;
  min-width: 140px;

  @media (max-width: 768px) {
    width: 36px;
    height: 26px;
    font-size: 12px;
    min-width: 36px;
  }
`;

export const IconBox = styled(Box)<{ type: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  text-align: center;
  height: 36px;
  background-color: ${({ type }) => {
    switch (type) {
      case "Added":
        return "#008236";
      case "Updated":
        return "#ccab00";
      case "Edited":
        return "#0082f5";
      case "Deleted":
        return "#D50000";
      case "Fixed":
        return "#282828";
      case "Security":
        return "#00B0FF";
      case "Changed":
        return "#6a00ef";
      case "Renamed":
        return "#FFD600";
      default:
        return "#000";
    }
  }};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  svg {
    color: #fff;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    height: 26px;
    width: 100%;
    border-radius: 4px;
    svg {
      font-size: 14px;
    }
    font-size: 12px;
  }
`;

export const IconTextBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 36px;
  text-align: center;

  @media (max-width: 768px) {
    height: 26px;
    font-size: 12px;
    display: none;
  }
`;

export const Feature = styled(Box)`
  @media (max-width: 768px) {
    font-size: 14px;
  }
  a {
    color: #0082f5;
    text-decoration: none;
  }
`;
