import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "..";
import { PaginationReponse } from "../../interfaces/general";
import { User, UserLevel, UserRole } from "../../interfaces/user";
import { HttpRequest, RequestType } from "../../plugins/httpRequest";

export const getUsers = createAsyncThunk(
  "users",
  async (
    {
      page = 1,
      pageSize = 5,
      level,
    }: { page?: number; pageSize?: number; role?: UserRole; level?: UserLevel },
    { getState }
  ) => {
    const state = getState() as RootState;

    const response = HttpRequest<any, PaginationReponse<User>>({
      url: `Admin/Users/GetAll`,
      method: RequestType.POST,
      body: {
        Type: "and",
        Items: [
          {
            Name: "countryId",
            Value: state.countries.countryId,
            Type: "=",
          },
          {
            Name: "role",
            Values: [UserRole.ANON, UserRole.USER],
            Type: "in",
          },
          ...(level
            ? [
                {
                  Name: "level",
                  Value: level,
                  Type: "=",
                },
              ]
            : []),
        ],
      },
      params: {
        skip: (page - 1) * pageSize,
        take: pageSize,
         orderBy: "createdAt desc",
      },
    });
    return response;
  }
);

export const updateUser = createAsyncThunk(
  "updateUser",
  async function ({ user }: { user: Partial<User> }) {
    const res = HttpRequest<Partial<User>, User>({
      url: `Admin/Users/Update`,
      method: RequestType.POST,
      body: user,
    });

    return res;
  }
);
