const CircleDownArrowIcon = (props: any) => (
  <svg
    data-name="Group 28886"
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    {...props}
  >
    <g
      data-name="Ellipse 4 copy 6"
      fill="rgba(109,92,174,0)"
      stroke={props.color ? props.color : "#fff"}
      strokeLinejoin="round"
    >
      <circle cx={9} cy={9} r={9} stroke="none" />
      <circle cx={9} cy={9} r={8.5} fill="none" />
    </g>
    <path
      data-name="Arrow copy 19"
      d="M9 9.346 6.464 7l-1.47 1.361 3.935 3.644.067-.063.067.062L13 8.363 11.527 7Z"
      fill={props.color ? props.color : "#fff"}
    />
  </svg>
);

export default CircleDownArrowIcon;
