import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Layout from "../../components/layout";
import {
  CardsWrapper,
  DashboardWrapper,
  Header,
  LeftSection,
  MapWrapper,
  RightSection,
} from "./styles";
import Map from "../../components/map";
import LiveVotes from "../../components/liveVotes";
import { useAppDispatch, useAppSelector } from "../../services";
import { getUsersByCountry } from "../../services/dashboardSlice/api";
import { getCategories } from "../../services/categoriesSlice/api";
import CountryCard from "./components/CountryCard";
import CategoryCard from "./components/CategoryCard";
import AnonCard from "./components/AnonCard";
import ConfirmedProfileCard from "./components/ConfirmedProfileCard";
import EnhancedProfileCard from "./components/EnhancedProfileCard";
import BestCard from "./components/BestCard";
import renderSkeleton from "../../utils/SkeletonUtils";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { countryId, countries } = useAppSelector((state) => state.countries);
  const [usersData, setUsersData] = useState<any>([]);
  const user = useAppSelector((state) => state.auth.user);
  const categories = useAppSelector((state) => state.categories.categories);

  useEffect(() => {
    dispatch(getCategories({ user, getAll: true }));
    const getUsersData = async () => {
      const response = await dispatch(getUsersByCountry({ countryId }));
      setUsersData(response.payload);
    };
    getUsersData();
    getCoordinations(getCountryName);
    // eslint-disable-next-line
  }, [countryId, user, dispatch]);

  const getCountryName = countries?.find(
    (country) => country.id === countryId
  )?.name;

  const getCurrentCountry = countries?.find(
    (country) => country.id === countryId
  );

  const getCoordinations = (countryName: string) => {
    switch (countryName) {
      case "Turkey":
        setMapData({ coords: [35, 38.5], zoom: 5.4 });
        break;
      case "Singapore":
        setMapData({ coords: [103.7662896, 1.3490348], zoom: 10 });
        break;
      case "United States":
        setMapData({ coords: [-97, 39.3812661305678], zoom: 3 });
        break;
      case "Russia":
        setMapData({ coords: [96, 61], zoom: 3 });
        break;
      case "China":
        setMapData({ coords: [103.7662896, 35.3490348], zoom: 3 });
        break;
      case "Norway":
        setMapData({ coords: [10, 60], zoom: 4.4 });
        break;
      case "Australia":
        setMapData({ coords: [133, -25], zoom: 4.4 });
        break;
      case "United Kingdom":
        setMapData({ coords: [-2, 54], zoom: 4.4 });
        break;
      default:
        setMapData({ coords: [35, 38.5], zoom: 5.2 });
        break;
    }
  };

  const [mapData, setMapData] = useState<any>({
    coords: [35, 38.5],
    zoom: 5.4,
  });

  return (
    <Layout>
      <Header></Header>
      <DashboardWrapper>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={9}>
            <LeftSection>
              <CardsWrapper>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    {(countries?.length !== 0) && (usersData?.length !== 0) ? (
                      <CountryCard
                        getCountryName={getCountryName}
                        currentCountry={getCurrentCountry}
                        countryId={countryId}
                        usersData={usersData}
                      />
                    ) : (
                      renderSkeleton({
                        height: "100%",
                        style: { minHeight: 150 },
                        baseColor: "#d1d1d1",
                      })
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {(categories?.length !== 0 ) && (usersData?.length !== 0) ? (
                      <CategoryCard />
                    ) : (
                      renderSkeleton({
                        height: "100%",
                        style: { minHeight: 150 },
                        baseColor: "#d1d1d1",
                      })
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {(categories?.length !== 0 ) && (usersData?.length !== 0) ? (
                      <BestCard />
                    ) : (
                      renderSkeleton({
                        height: "100%",
                        style: { minHeight: 150 },
                        baseColor: "#d1d1d1",
                      })
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    {usersData?.length !== 0 ? (
                      <AnonCard usersData={usersData} />
                    ) : (
                      renderSkeleton({
                        height: "100%",
                        style: { minHeight: 150 },
                        baseColor: "#d1d1d1",
                      })
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {usersData?.length !== 0 ? (
                      <ConfirmedProfileCard usersData={usersData} />
                    ) : (
                      renderSkeleton({
                        height: "100%",
                        style: { minHeight: 150 },
                        baseColor: "#d1d1d1",
                      })
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {usersData?.length !== 0 ? (
                      <EnhancedProfileCard usersData={usersData} />
                    ) : (
                      renderSkeleton({
                        height: "100%",
                        style: { minHeight: 150 },
                        baseColor: "#d1d1d1",
                      })
                    )}
                  </Grid>
                </Grid>
              </CardsWrapper>
              <Grid item xs={12}>
                <MapWrapper>
                  <Map mapData={mapData} />
                </MapWrapper>
              </Grid>
            </LeftSection>
          </Grid>
          <Grid item xs={12} lg={3}>
            <RightSection>
              <LiveVotes />
            </RightSection>
          </Grid>
        </Grid>
      </DashboardWrapper>
    </Layout>
  );
};

export default Dashboard;
