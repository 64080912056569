import { Box, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";

const JobStatusStats = ({ analyticsData }: any) => {
  const [jobStatusNames, setJobStatusNames] = useState<
    { id: string; label: string; value: number }[]
  >([]);

  useEffect(() => {
    const counts = analyticsData.reduce((acc: any, item: any) => {
      if (item.jobStatusName) {
        acc[item.jobStatusName] = (acc[item.jobStatusName] ?? 0) + 1;
      }
      return acc;
    }, {});

    const jobStatus = Object.keys(counts).map((name) => ({
      id: name,
      label: name,
      value: counts[name],
    }));

    setJobStatusNames(jobStatus);
  }, [analyticsData]);

  const noData = [
    {
      id: "noData",
      label: "NoData",
      value: 1,
      color: "#dcdcdc",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
      }}
    >
      <Box bgcolor={"white"} sx={{ width: "100%" }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          py={2}
          borderBottom={0.5}
          borderColor={"#C2C2C2"}
        >
          <Typography>Çalışma Durumu Dağılımı</Typography>
        </Box>
        <Box
          p={1}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "270px",
          }}
        >
          {jobStatusNames.length > 0 ? (
            <ResponsivePie
              data={jobStatusNames}
              margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
              innerRadius={0.05}
              padAngle={2}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              arcLabelsTextColor="#ffffff"
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              enableArcLinkLabels={false}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: 20,
                  itemsSpacing: 10,
                  itemWidth: 40,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]}
            />
          ) : (
            <ResponsivePie
              data={noData}
              arcLabelsTextColor="#ffffff"
              margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
              innerRadius={0.5}
              padAngle={1.3}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              enableArcLinkLabels={false}
              arcLinkLabelsTextColor="#333333"
              colors={{ datum: "data.color" }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default JobStatusStats;
