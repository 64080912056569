import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
`;

export const PreviewContainer = styled.div``;


export const CardContainer = styled.div``;


export const SettingsContainer = styled.div`
  margin-left: 40px;

`;
