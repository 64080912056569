const MeatballsMenuIcon = (props: any) => {
  return (
    <svg
      data-name="Component 44 \u2013 8"
      xmlns="http://www.w3.org/2000/svg"
      width={34}
      height={16}
      {...props}
    >
      <g data-name="Group 28924">
        <path data-name="Rectangle 657" fill="#fff" d="M0 0h34v16H0z" />
        <g
          data-name="Group 28784"
          fill="none"
          stroke={props.color ? props.color : "#000"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={5}
        >
          <path d="M25.98 8h.021" opacity={0.34} />
          <path
            data-name="Vector"
            d="M16.991 8h.021M8.001 8h.021"
            opacity={0.34}
          />
        </g>
      </g>
    </svg>
  );
};

export default MeatballsMenuIcon;
