import { createAsyncThunk } from "@reduxjs/toolkit";

import { HttpRequest, RequestType } from "../../plugins/httpRequest";
import { DynamicCardResponse } from "../../interfaces/dynamic";

export const getCardDynamic = createAsyncThunk(
  "getCards",
  async ({ categoryId }: { categoryId: string }, { rejectWithValue }) => {
    try {
      const result = await HttpRequest<any, { data: DynamicCardResponse[] }>({
        url: `Admin/VotesView/GetDynamic`,
        method: RequestType.POST,
        body: {
          Columns: [
            {
              Name: "cardTitle",
            },
            {
              Name: "createdAt",
            },
            {
              Name: "role",
            },

            {
              Name: "level",
            },
            {
              Name: "cardId",
            },
            {
              Name: "id",
              AggregateFunction: "count",
              Alias: "count",
            },
            {
              Name: "optionLeftStat",
              AggregateFunction: "sum",
              Alias: "optionLeftStat",
            },
            {
              Name: "optionRightStat",
              AggregateFunction: "sum",
              Alias: "optionRightStat",
            },
            {
              Name: "optionTopStat",
              AggregateFunction: "sum",
              Alias: "optionTopStat",
            },
          ],
          Where: {
            TYPE: "AND",
            ITEMS: [
              {
                NAME: "userStatus",
                VALUE: "ACTIVE",
                TYPE: "=",
              },
              {
                NAME: "categoryId",
                VALUE: categoryId,
                TYPE: "=",
              },
            ],
          },
          OrderColumns: [
            {
              Name: "createdAt",
              "isDescending": true
            },
          ],
        },
      });

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
