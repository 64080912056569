import { useState, useEffect } from "react";
import NumberOfUsers from "../../../components/dashboardCards/numberOfUsers/index";
import ReactCountryFlag from "react-country-flag"

const CountryCard = ({ usersData, getCountryName, currentCountry }: any) => {
  const [registeredThisWeekCount, setRegisteredThisWeekCount] = useState<number>(0);
  const [registeredThisMonthCount, setRegisteredThisMonthCount] = useState<number>(0);
  const [registeredThisYearCount, setRegisteredThisYearCount] = useState<number>(0);
  const [osStats, setOsStats] = useState<any>({});

  useEffect(() => {
    const forFilteringData = usersData?.data;

    const osStats = {
      ios: 0,
      android: 0,
    };

    forFilteringData?.forEach((item: any) => {
      const os = item.deviceOs;
    
      if (os.includes("iOS")) {
        osStats.ios++;
      }
    
      if (os.includes("Android")) {
        osStats.android++;
      }
    });

    setOsStats(osStats);

    
    const thisWeekRegisteredCount = forFilteringData?.filter((user: any) => {
      const date = new Date(user.registeredDate);
      const today = new Date();
      const week = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7
      );
      return date > week;
    });
    setRegisteredThisWeekCount(thisWeekRegisteredCount?.length);

    const thisMonthRegisteredCount = forFilteringData?.filter((user: any) => {
      const date = new Date(user.registeredDate);
      const today = new Date();
      const month = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 30
      );
      return date > month;
    });
    setRegisteredThisMonthCount(thisMonthRegisteredCount?.length);

    const thisYearRegisteredCount = forFilteringData?.filter((user: any) => {
      const date = new Date(user.registeredDate);
      const today = new Date();
      const year = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 365
      );
      return date > year;
    });
    setRegisteredThisYearCount(thisYearRegisteredCount?.length);
  }, [usersData]);

  const getCountryData = () => {
    const commonData = {
      id: "countryCard",
      title: getCountryName,
      total: usersData?.length,
      icon: <ReactCountryFlag countryCode={currentCountry.code} svg style={{ aspectRatio: "auto 4 / 3", width: 40, height: 40 }} title={currentCountry.name} />,
      thisWeek: registeredThisWeekCount || -1,
      thisMonth: registeredThisMonthCount || -1,
      thisYear: registeredThisYearCount || -1,
    };
  
    switch (getCountryName) {
      case "Turkey":
        return { ...commonData, color: "#ED0505" };
      case "Singapore":
        return { ...commonData, color: "#ed2939" };
      case "Russia":
        return { ...commonData, color: "#264aad" };
      case "United States":
        return { ...commonData, color: "#b32235" };
      case "Norway":
        return { ...commonData, color: "#EF2B2D" };
      case "China":
        return { ...commonData, color: "#DE2910" };
      case "Australia":
        return { ...commonData, color: "#00247D" };
      case "United Kingdom":
        return { ...commonData, color: "#00247D" };
      case "India":
        return { ...commonData, color: "#FF9933" };
      case "Germany":
        return { ...commonData, color: "#000000" };
      case "France":
        return { ...commonData, color: "#002395" };
      case "Czechia":
        return { ...commonData, color: "#11457e" };
      default:
        return { ...commonData, color: "#000000"}
    }
  };
  return <NumberOfUsers data={getCountryData()} appleStats={osStats?.ios} androidStats={osStats?.android} />;
};

export default CountryCard;
