import {
  Box,
  // FormControl,
  Grid,
  // MenuItem,
  Pagination,
  // Select,
  // Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  RadialLinearScale,
  BarElement,
} from "chart.js";
import {
  CardsWrapper,
  Header,
  PassiveCardsWrapper,
  // AnalyticsData,
} from "./styles";
import usePagination from "../../utils/usePagination";
import CategoryCard from "../categories/components/categoryCard";
import ArchiveCard from "../archives/components/archiveCard";
import CustomAnalyticsTable from "./components/customAnalyticsTable";
import { useAppDispatch, useAppSelector } from "../../services";
import { Category } from "../../interfaces/category";
// import { getCountries } from "../../services/countries";
import { getCategories } from "../../services/categoriesSlice";
import { CATEGORY_PER_PAGE } from "../categories";
import { getCardDynamic } from "../../services/analyticsSlice/api";
// import { useSelector } from "react-redux";
import { DynamicCard } from "../../interfaces/dynamic";
import TotalUserTypeStats from "./components/stats/TotalUserTypeStats";
import VoteWeekStats from "./components/stats/VoteWeekStats";
import GenderStats from "./components/stats/GenderStats";
import KidsCountStats from "./components/stats/KidsCountStats";
import TotalVoteStats from "./components/stats/TotalVoteStats";
import BirthDateStats from "./components/stats/BirthDateStats";
import BirthPlaceStats from "./components/stats/BirthPlaceStats";
import LivedCityStats from "./components/stats/LivedCityStats";
import EducationLevelStats from "./components/stats/EducationLevelStats";
import EthnicGroupStats from "./components/stats/EthnicGroupStats";
import JobStatusStats from "./components/stats/JobStatusStats";
import ProfessionStats from "./components/stats/ProfessionStats";
import IncomeStats from "./components/stats/IncomeStats";
import FootballTeamStats from "./components/stats/FootballTeamStats";
import ZodiacSignStats from "./components/stats/ZodiacSignStats";

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const Analytics = () => {
  const dispatch = useAppDispatch();
  const [selectedData, setSelectedData] = useState<DynamicCard | null>(null);
  // const [filters, setFilters] = useState("");
  // const [cardsFilter, setCardsFilter] = useState("");
  // const [platform, setPlatform] = useState("");
  const [archiveCardItem, setArchiveCardItem] = useState<Category | null>(null);
  const user = useAppSelector((state) => state.auth.user);

  const { categories } = useAppSelector((state) => state.categories);
  const { countryId } = useAppSelector((state) => state.countries);

  let [page, setPage] = useState(1);
  const PER_PAGE = CATEGORY_PER_PAGE;

  const count = Math.ceil(categories.length / PER_PAGE);
  const _DATA = usePagination(categories, PER_PAGE);
  const categoriesData = usePagination<Category>(categories.data, PER_PAGE);
  const cards = useAppSelector((state) => state.analytics.cards);
  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  
  useEffect(() => {
    if (archiveCardItem) {
      dispatch(getCardDynamic({ categoryId: archiveCardItem?.id! }));
    }
  }, [archiveCardItem, dispatch]);
  useEffect(() => {
    dispatch(getCategories({ page, user }));
  }, [page, dispatch, countryId, user]);


  const [analyticsData, setAnalyticsData] = useState<any>([]);
  const token = JSON.parse(window.localStorage.getItem("token") || "");

  useEffect(() => {
    if (!selectedData) {
      return;
    }

    fetch("https://api.svayp.com/Admin/VotesView/GetDynamic", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        Columns: [
          {
            Name: "genderName",
          },
          {
            Name: "optionLeftStat",
            AggregateFunction: "sum",
            Alias: "leftVotes",
          },
          {
            Name: "optionRightStat",
            AggregateFunction: "sum",
            Alias: "rightVotes",
          },
          {
            Name: "optionTopStat",
            AggregateFunction: "sum",
            Alias: "topVotes",
          },
          {
            Name: "educationLevelName",
          },
          {
            Name: "ethnicGroupName",
          },
          {
            Name: "jobStatusName",
          },
          {
            Name: "professionName",
          },
          {
            Name: "incomeGroupName",
          },
          {
            Name: "footballTeamName",
          },
          {
            Name: "birthDay",
          },
          {
            Name: "birthPlaceName",
          },
          {
            Name: "cityName",
          },
          {
            Name: "kids",
          },
          {
            Name: "role",
          },
          {
            Name: "level",
          },
          {
            Name: "id",
            AggregateFunction: "count",
            Alias: "count",
          },
        ],
        Where: {
          NAME: "cardId",
          VALUE: selectedData?.cardId,
          TYPE: "=",
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setAnalyticsData(data.data);
      });
  }, [selectedData, token]);

  return (
    <Layout>
      <Header>
        {/*   <Typography variant="paragraph">Filters: </Typography>
        <FormControl sx={{ width: "150px", ml: "16px" }}>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={filters}
            onChange={(e: any) => setFilters(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "36px", fontSize: "14px" }}
          >
            <MenuItem value="">All Categories</MenuItem>
            <MenuItem value="turkey">Turkey</MenuItem>
            <MenuItem value="singapore">Singapore</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: "150px", ml: "16px" }}>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={cardsFilter}
            onChange={(e: any) => setCardsFilter(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "36px", fontSize: "14px" }}
          >
            <MenuItem value="">All Cards</MenuItem>
            <MenuItem value="turkıye">Türkiye</MenuItem>
            <MenuItem value="gundem">Gündem</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: "150px", ml: "16px" }}>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={platform}
            onChange={(e: any) => setPlatform(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "36px", fontSize: "14px" }}
          >
            <MenuItem value="">All Platforms</MenuItem>
            <MenuItem value="web">Web</MenuItem>
            <MenuItem value="mobile">Mobile</MenuItem>
          </Select>
        </FormControl> */}

        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-start",
            paddingLeft: "96px",
          }}
        >
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            showFirstButton
            showLastButton
          />
        </Box>
      </Header>
      <CardsWrapper>
        {!archiveCardItem &&
          categoriesData
            ?.currentData()
            .map((item, index: number) => (
              <CategoryCard
                key={`${item.title}-${index}`}
                item={item}
                onClick={() => setArchiveCardItem(item)}
              />
            ))}
        {archiveCardItem && (
          <>
            <ArchiveCard
              item={archiveCardItem}
              onClose={() => setArchiveCardItem(null)}
            />
            <PassiveCardsWrapper>
              {!selectedData && (
                <CustomAnalyticsTable
                  data={cards}
                  setSelectedData={setSelectedData}
                />
              )}
              {selectedData && (
                <CustomAnalyticsTable
                  data={[selectedData]}
                  setSelectedData={setSelectedData}
                  isSelected
                />
              )}
            </PassiveCardsWrapper>
          </>
        )}
      </CardsWrapper>
      {selectedData && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",

            justifyContent: "flex-start",
            gap: "20px",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              alignItems: "stretch",
            }}
          >
            <Grid item xs={12} md={4} xl={2}>
              <TotalUserTypeStats selectedData={selectedData} />
            </Grid>
            <Grid item xs={12} md={8} xl={4}>
              <Grid item>
                <GenderStats analyticsData={analyticsData} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <TotalVoteStats selectedData={selectedData} />
            </Grid>
            <Grid item xs={12} md={6} xl={5}>
              <BirthDateStats analyticsData={analyticsData} />
            </Grid>
            <Grid item xs={12} md={3} xl={2}>
              <KidsCountStats analyticsData={analyticsData} />
            </Grid>
            <Grid item xs={12} md={6} xl={5}>
              <VoteWeekStats selectedData={selectedData} />
            </Grid>
            <Grid item xs={12} md={3} xl={2}>
              <BirthPlaceStats analyticsData={analyticsData} />
            </Grid>
            <Grid item xs={12} md={3} xl={2}>
              <LivedCityStats analyticsData={analyticsData} />
            </Grid>
            <Grid item xs={12} md={3} xl={2}>
              <EducationLevelStats analyticsData={analyticsData} />
            </Grid>
            <Grid item xs={12} md={3} xl={2}>
              <EthnicGroupStats analyticsData={analyticsData} />
            </Grid>
            <Grid item xs={12} md={3} xl={2}>
              <JobStatusStats analyticsData={analyticsData} />
            </Grid>
            <Grid item xs={12} md={3} xl={2}>
              <ProfessionStats analyticsData={analyticsData} />
            </Grid>
            <Grid item xs={12} md={3} xl={2}>
              <IncomeStats analyticsData={analyticsData} />
            </Grid>
            <Grid item xs={12} md={3} xl={2}>
              <FootballTeamStats analyticsData={analyticsData} />
            </Grid>
            <Grid item xs={12} md={3} xl={2}>
              <ZodiacSignStats analyticsData={analyticsData} />
            </Grid>
            <Grid item xs={12} md={3} xl={4}>
              Konumu
            </Grid>
          </Grid>
        </Box>
      )}
      <Box sx={{ height: "80px" }} />
    </Layout>
  );
};

export default Analytics;
