import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import cardsSlice from "./cardsSlice";
import layoutSlice from "./layoutSlice";
import authSlice from "./authSlice";
import categoriesSlice from "./categoriesSlice";
import optionsSlice from "./optionsSlice";
import languagesSlice from "./languagesSlice";
import usersSlice from "./usersSlice";
import citiesSlice from "./citiesSlice";
import countriesSlice from "./countries";
import moderatorsSlice from "./moderatorsSlice";
import analyticsSlice from "./analyticsSlice";
import dashboardSlice from "./dashboardSlice";

const store = configureStore({
  reducer: {
    cards: cardsSlice,
    layout: layoutSlice,
    auth: authSlice,
    categories: categoriesSlice,
    options: optionsSlice,
    countries: countriesSlice,
    languages: languagesSlice,
    users: usersSlice,
    cities: citiesSlice,
    moderators: moderatorsSlice,
    analytics: analyticsSlice,
    dashboard: dashboardSlice,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
