import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AddCardImageIcon from "../../../../assets/cards/AddCardImageIcon";
import CropperModal from "../cropperModal";
import { dataType } from "../addCard";
import { CardFooter, CardHeader, CardPreview } from "./styles";
import { useAppSelector } from "../../../../services";
import { IMG_URL } from "../../../../constants";
import { getTitleSize } from "../../../../utils/helperFunctions";
import { useTranslation } from 'react-i18next';

type CardImageProps = {
  data: dataType;
  setData: any;
  showTopOption: boolean;
};

const CardImage: React.FC<CardImageProps> = ({ data, setData, showTopOption }) => {
  const imageRef = useRef() as any;
  const [cardImg, setCardImg] = useState() as any;
  const [isCropperModal, setCropperModal] = useState(false);
  const { options } = useAppSelector((state) => state.options);
  const { categories } = useAppSelector((state) => state.categories);

  useEffect(() => {
    setCardImg(null);
  }, []);

  const handleFileUpload = (e: any) => {
    setCropperModal(true);
    const media = e.target.files[0];
    setCardImg(media);
  };

  const getOptionName = (id?: string) => {
    return options?.find((item: any) => item.id === id)?.name || "";
  };

  const getCategoryTitle = (id?: string) => {
    const title =
      categories?.data?.find((item: any) => item.id === id)?.title || "";
    const media =
      categories?.data?.find((item: any) => item.id === id)?.media || "";
    return { media, title };
  };

  const { t } = useTranslation(["cards"]);

  return (
    <Box
      sx={{
        width: "484px",
        position: "sticky",
        top: "66px",
        zIndex: 999,
        height: "max-content",
      }}
    >
      <CropperModal
        open={isCropperModal}
        onClose={() => setCropperModal(false)}
        imageSrc={cardImg}
        setCroppedImgSrc={(croppedImgSrc: string) => {
          setData((prev: any) => {
            return { ...prev, croppedImgSrc };
          });
        }}
      />
      <Box
        sx={{
          width: "100%",
          height: "635px",
        }}
      >
        <input
          // @ts-ignore
          ref={imageRef}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          value=""
          onChange={handleFileUpload}
        />
        {!data.croppedImgSrc ? (
          <Box
            sx={{
              background: "#EBEBEB",
              width: "100%",
              height: "100%",
              borderRadius: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              // @ts-ignore
              imageRef.current.click();
            }}
          >
            <AddCardImageIcon />
          </Box>
        ) : (
          <CardPreview
            imgsrc={data.croppedImgSrc}
            onClick={() => {
              // @ts-ignore
              imageRef.current.click();
            }}
           
          >
            <CardHeader />
            <Box
              sx={{
                position: "absolute",
                zIndex: 999,
                display: "flex",
                alignItems: "center",
                width: "calc(100% - 48px)",
                margin: "24px",
                gap: "12px",
              }}
            >
              {data.category && (
                <img
                  src={IMG_URL + getCategoryTitle(data.category)?.media}
                  alt="category-icon"
                  style={{
                    width: "45px",
                    height: "45px",
                    borderRadius: "50%",
                    background: "#fff",
                  }}
                />
              )}
              <Typography color="#fff" variant="subTitle1" fontWeight={700}>
                {getCategoryTitle(data.category).title}
              </Typography>
            </Box>
            <Typography
              sx={{
                position: "absolute",
                zIndex: 999,
                display: "flex",
                justifyContent: "space-between",
                bottom: getOptionName(data.leftOption).length < 12 || getOptionName(data.rightOption).length < 12 ? "60px" : "80px",
                width: "calc(100% - 48px)",
                margin: "24px",
                fontSize: `${getTitleSize(data?.title)}px`,
                lineHeight: `${getTitleSize(data?.title) * 1.2}px`,
              }}
              color="#fff"
              variant="subTitle1"
              fontWeight={500}
            >
              {data.title}
            </Typography>
            <Box
              sx={{
                position: "absolute",
                zIndex: 999,
                display: "flex",
                justifyContent: "space-between",
                bottom: "0px",
                width: "calc(100% - 48px)",
                margin: "24px",
              }}
            >
              <Typography color="#ED0505" variant="subTitle1" fontWeight={700} textAlign="left">
                {getOptionName(data.leftOption)} <br /> 0%
              </Typography>
              <Typography color="#C2C2C2" variant="subTitle1" fontWeight={700} textAlign="center">
                {showTopOption ? getOptionName(data.topOption) : t("indecisive")} <br /> 0%
              </Typography>
              <Typography color="#34c759" variant="subTitle1" fontWeight={700} textAlign="right">
                {getOptionName(data.rightOption)} <br /> 0%
              </Typography>
            </Box>
            <CardFooter />
          </CardPreview>
        )}
      </Box>
    </Box>
  );
};

export default CardImage;
