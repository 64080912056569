const CountriesIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      {...props}
      viewBox="0 0 40 40"
    >
      <path
        fill={props.primarycolor ? "#ff9f27" : "#21252D"}
        d="M0 0H40V40H0z"
      ></path>
      <g
        fill={props.primarycolor ? "#fff" : "#778095"}
        clipPath="url(#clip0_209_134)"
      >
        <path
          d="M26.4 22.874a7.116 7.116 0 01-2.269 2.726l-.695-.69a.595.595 0 00-.27-.154l-1.595-.417a.585.585 0 01-.437-.656l.177-1.193a.588.588 0 01.36-.458l2.262-.933a.598.598 0 01.63.11l1.837 1.665zm-4.094-4.692l1.647-1.9a.586.586 0 00.144-.384V14.13a7.178 7.178 0 00-9.23.762 7.028 7.028 0 00-.69 9.156l.738-.48a.592.592 0 00.267-.489l.015-2.669a.59.59 0 01.1-.324l1.556-2.306a.592.592 0 01.627-.245.596.596 0 01.216.098l1.471.957a.603.603 0 00.43.107l2.336-.313a.596.596 0 00.373-.202z"
          opacity="0.2"
        ></path>
        <path d="M20 12a8 8 0 108 8 8.008 8.008 0 00-8-8zm0 1.23a6.731 6.731 0 013.692 1.1v1.516l-1.705 1.98-2.42.328-.025-.017-1.513-.99a1.23 1.23 0 00-1.731.322l-1.611 2.408c-.134.2-.207.436-.208.678l-.018 2.787-.251.165A6.77 6.77 0 0120 13.23zm-5.055 11.268l.194-.127a1.23 1.23 0 00.553-1.022l.017-2.788 1.612-2.407a.276.276 0 00.024.017l1.514.99c.253.179.565.255.872.213l2.423-.329c.299-.04.572-.188.77-.416l1.704-1.981c.191-.224.296-.508.295-.802v-.487a6.76 6.76 0 011.52 6.718l-1.24-1.136a1.23 1.23 0 00-1.303-.23l-2.343.973a1.238 1.238 0 00-.745.957l-.184 1.246a1.23 1.23 0 00.906 1.37l1.65.435.182.183a6.76 6.76 0 01-8.42-1.377zm9.44.656l-.331-.332a1.23 1.23 0 00-.559-.321l-1.649-.436.184-1.245 2.342-.974 1.551 1.422a6.802 6.802 0 01-1.538 1.886z"></path>
      </g>
    </svg>
  );
};

export default CountriesIcon;
