import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getCards, postCard, updateCard, deleteCard } from "./api";
import { Card } from "../../interfaces/card";
import { PaginationReponse } from "../../interfaces/general";
export { getCards, postCard, updateCard, deleteCard };
export interface CardsState {
  cards: PaginationReponse<Card>;
  /* meta: any;
  skip: number;
  cardsLength: number; */
  loading: boolean;
  error: any;
}

const initialState: CardsState = {
  cards: { data: [], length: 0, skip: 0, take: 0 },
  /* meta: null,
  skip: 0,
  cardsLength: 0, */
  loading: true,
  error: "",
};

export const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    /* incrementSkip: (state) => {
      state.skip += 1;
    },
    decrementSkip: (state) => {
      state.skip -= 1;
    },
    resetSkip: (state) => {
      state.skip = 0;
    },
    setSkip: (state, action) => {
      state.skip = action.payload;
    }, */
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCards.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getCards.fulfilled, (state, action) => {
        if (action.payload) {
          state.cards = action.payload;
        }
        state.loading = false;
      })
      .addCase(getCards.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching cards data!";
        toast.error("Get cards failed!");
      });

    builder
      .addCase(postCard.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(postCard.fulfilled, (state) => {
        state.loading = false;
        toast.success("Card created!");
      })
      .addCase(postCard.rejected, (state) => {
        state.loading = false;
        state.error = "Error post card!";
        // toast.error("Something went wrong!");
      });

    builder
      .addCase(updateCard.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateCard.fulfilled, (state, action) => {
        state.loading = false;
        state.cards.data = state.cards.data.map((card) =>
          card.id === action.payload.id
            ? { ...card, status: action.payload.status }
            : card
        );
        toast.success("Card updated!");
        setTimeout(() => {
          window.location.href = "/cards";
        }, 1300);
      })
      .addCase(updateCard.rejected, (state) => {
        state.loading = false;
        state.error = "Error update card!";
        toast.error("Something went wrong!");
      });

    builder
      .addCase(deleteCard.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteCard.fulfilled, (state) => {
        state.loading = false;
        toast.success("Card deleted!");
        setTimeout(() => {
          window.location.href = "/cards";
        }, 1300);
      })
      .addCase(deleteCard.rejected, (state) => {
        state.loading = false;
        state.error = "Error delete card!";
        toast.error("Something went wrong!");
      });
  },
});

//export const { incrementSkip, decrementSkip, resetSkip, setSkip } =
//  cardsSlice.actions;

export default cardsSlice.reducer;
