const UsersIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={40}
    height={40}
    {...props}
  >
    <path
      fill={props.primarycolor ? "#ff9f27" : "#21252D"}
      d="M0 0H40V40H0z"
    ></path>
    <path
      fill={props.primarycolor ? "#fff" : "#778095"}
      d="M20.5 18.75a3.25 3.25 0 11-6.5 0 3.25 3.25 0 016.5 0z"
      opacity="0.2"
    ></path>
    <path
      fill={props.primarycolor ? "#fff" : "#778095"}
      d="M19.318 21.792a3.638 3.638 0 001.477-1.778 3.402 3.402 0 00.061-2.258 3.607 3.607 0 00-1.378-1.847 3.972 3.972 0 00-2.274-.709c-.82 0-1.617.249-2.274.709a3.607 3.607 0 00-1.378 1.847c-.237.74-.216 1.53.061 2.258.277.727.795 1.35 1.476 1.778a6.045 6.045 0 00-3.002 2.26.466.466 0 00-.077.364.495.495 0 00.22.306.528.528 0 00.57-.009.492.492 0 00.139-.136 4.986 4.986 0 011.839-1.602 5.334 5.334 0 012.426-.58c.847 0 1.681.2 2.426.58a4.986 4.986 0 011.84 1.602c.074.104.189.177.32.202.131.025.267 0 .38-.068a.48.48 0 00.218-.3.457.457 0 00-.067-.358 6.044 6.044 0 00-3.003-2.261zm-4.912-2.993c0-.522.164-1.032.471-1.466a2.77 2.77 0 011.256-.971 2.956 2.956 0 011.617-.15 2.855 2.855 0 011.433.722c.39.368.657.839.765 1.35a2.499 2.499 0 01-.159 1.524c-.212.483-.57.895-1.03 1.184a2.92 2.92 0 01-1.555.445 2.89 2.89 0 01-1.978-.774 2.567 2.567 0 01-.82-1.864zm13.363 5.918a.534.534 0 01-.382.067.51.51 0 01-.322-.207 4.976 4.976 0 00-1.838-1.602 5.325 5.325 0 00-2.427-.579.525.525 0 01-.36-.14.466.466 0 01-.149-.34c0-.127.054-.249.15-.338a.525.525 0 01.36-.141c.411 0 .818-.087 1.19-.252.373-.166.703-.407.965-.707s.45-.65.553-1.027a2.49 2.49 0 00.036-1.15 2.564 2.564 0 00-.487-1.057 2.754 2.754 0 00-.918-.76 2.952 2.952 0 00-2.38-.136.537.537 0 01-.562-.102.478.478 0 01-.148-.347.458.458 0 01.161-.34.514.514 0 01.172-.1 4.031 4.031 0 012.735-.034 3.754 3.754 0 012.056 1.7c.439.788.553 1.7.32 2.562a3.61 3.61 0 01-1.58 2.105 6.044 6.044 0 013.003 2.26c.074.107.1.237.072.362a.48.48 0 01-.22.302z"
    ></path>
  </svg>
);

export default UsersIcon;
