import { Typography } from "@mui/material";
import { Box, styled } from "@mui/material";

export const Wrapper = styled(Box)`
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;

  width: 340px;
  height: 550px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

export const CardHeader = styled(Box)`
  width: 100%;
  height: 104px;
  padding: 12px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardHeaderItem = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const EnableCardButton = styled(Box)<{ borderColor: string }>`
  height: 30px;
  padding: 6px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  border: ${(props) => `2px solid ${props.borderColor}`};

  border-radius: 15px;
  margin-bottom: 12px;
`;

export const CardImageWrapper = styled(Box)`
  width: 100%;
  height: 446px;
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

export const CardImage = styled(Box)<{ imgsrc: string }>`
  /* background: ${(props) => `url(${props.imgsrc})`}; */
  background: ${(props) =>
    `linear-gradient( #00000040 100%, #00000040 100%), url(${props.imgsrc})`};
  background-size: 100% 100%;

  position: absolute;
  bottom: 0;

  width: 100%;
  height: 446px;

  padding: 16px;
  border-radius: 12px;
`;

export const CardBack = styled(Box)<{ showback: number }>`
  background: linear-gradient(#ffffffcc 100%, #ffffffcc 100%);
  background-size: 100% 100%;

  position: absolute;

  width: 100%;
  height: 100%;

  padding: 16px;
  border-radius: 8px;

  bottom: ${(props) => (props.showback ? "100%" : 0)};
  transform: translateY(100%);
  transition: all 0.3s ease;
  will-change: bottom;
`;

export const CardBackItemTitle = styled(Typography)`
  color: #000;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.25s ease;
  
  &:hover {
   color: #ff9f27;
  }
`;