const UpArrowIcon = (props: any) => (
  <svg
    data-name="vuesax/twotone/arrow-up"
    xmlns="http://www.w3.org/2000/svg"
    width={27.004}
    height={27.004}
    {...props}
  >
    <g
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        d="M24.788 13.502A11.286 11.286 0 1 1 13.502 2.216a11.286 11.286 0 0 1 11.286 11.286Z"
        stroke={props.color ? props.color : "#fff"}
      />
      <g data-name="Group 645" opacity={0.4} stroke="#000">
        <path
          data-name="Vector"
          d="M13.502 17.38v-6.648M10.178 12.948l3.324-3.324 3.324 3.324"
        />
      </g>
    </g>
  </svg>
);

export default UpArrowIcon;
