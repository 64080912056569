import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import MeatballsMenuIcon from "../../assets/cards/MeatballsMenuIcon";
import CustomTable, { Column } from "../../components/customTable";
import Layout from "../../components/layout";
import { activityLogsData } from "../../mock/activityLogsData";
import usePagination from "../../utils/usePagination";
import { Header } from "./styles";

const ActivityLogs = () => {
  const [filters, setFilters] = useState("");

  const cardsArray = new Array(20).fill({});

  let [page, setPage] = useState(1);
  const PER_PAGE = 8;

  const count = Math.ceil(cardsArray.length / PER_PAGE);
  const _DATA = usePagination(cardsArray, PER_PAGE);

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  const columns: Column<any>[] = [
    {
      id: "name",
      label: "NAME",
      width: "15%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.name}
        </Typography>
      ),
    },
    {
      id: "datetime",
      label: "DATE/TIME",
      width: "15%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.datetime}
        </Typography>
      ),
    },
    {
      id: "duration",
      label: "DURATION",
      width: "15%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.duration}
        </Typography>
      ),
    },
    {
      id: "executable",
      label: "EXECUTABLE",
      width: "15%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.executable}
        </Typography>
      ),
    },
    {
      id: "region",
      label: "REGION",
      width: "15%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.region}
        </Typography>
      ),
    },
    {
      id: "cardCount",
      label: "CARD COUNT",
      width: "10%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.cardCount}
        </Typography>
      ),
    },
    {
      id: "userType",
      label: "USER TYPE",
      width: "10%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.userType}
        </Typography>
      ),
    },
    {
      id: "menu",
      label: "",
      width: "10%",
      render: (item) => <MeatballsMenuIcon />,
    },
  ];

  return (
    <Layout>
      <Header>
        <Typography variant="paragraph">Filters: </Typography>
        <FormControl sx={{ width: "150px", ml: "16px" }}>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={filters}
            onChange={(e: any) => setFilters(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "36px", fontSize: "14px" }}
          >
            <MenuItem value="">All Users</MenuItem>
            <MenuItem value="superadmin">Super Admin</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            showFirstButton
            showLastButton
          />
        </Box>
      </Header>
      <CustomTable
        title="Activity Logs"
        columns={columns}
        data={activityLogsData}
      />
    </Layout>
  );
};

export default ActivityLogs;
