const CategoriesIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    {...props}
    fill="none"
  >
    <path
      fill={props.primarycolor ? "#ff9f27" : "#21252D"}
      d="M0 0H40V40H0z"
    ></path>
    <path
      fill={props.primarycolor ? "#fff" : "#778095"}
      d="M28 15.6v3.598h-4.734a.714.714 0 00-.421.136.593.593 0 00-.232.344 2.37 2.37 0 01-.925 1.38A2.855 2.855 0 0120 21.6a2.855 2.855 0 01-1.688-.542 2.37 2.37 0 01-.925-1.38.593.593 0 00-.232-.344.714.714 0 00-.42-.136H12V15.6c0-.319.14-.624.39-.849.25-.225.59-.351.943-.351h13.334c.353 0 .692.126.942.351.25.225.391.53.391.849z"
      opacity="0.2"
    ></path>
    <path
      fill={props.primarycolor ? "#fff" : "#778095"}
      d="M26.154 13.6H13.846c-.49 0-.96.202-1.305.563A1.96 1.96 0 0012 15.52v8.96c0 .51.194.998.54 1.358.347.36.817.562 1.306.562h12.308c.49 0 .96-.202 1.305-.562.346-.36.541-.849.541-1.358v-8.96c0-.51-.195-.997-.54-1.357a1.81 1.81 0 00-1.306-.563zM13.23 17.44h13.538v1.28h-3.754c-.284 0-.559.102-.779.29a1.29 1.29 0 00-.427.735c-.086.432-.313.82-.642 1.1-.33.278-.74.43-1.166.43-.424 0-.836-.152-1.165-.43a1.934 1.934 0 01-.642-1.1 1.289 1.289 0 00-.428-.737 1.202 1.202 0 00-.78-.288H13.23v-1.28zm.615-2.56h12.308c.163 0 .32.067.435.188.115.12.18.282.18.452v.64H13.231v-.64c0-.17.065-.332.18-.452a.604.604 0 01.435-.188zm12.308 10.24H13.846a.603.603 0 01-.435-.187.653.653 0 01-.18-.453V20h3.754c.141.723.519 1.373 1.068 1.84.55.466 1.237.721 1.947.721.709 0 1.397-.255 1.946-.721A3.224 3.224 0 0023.015 20h3.754v4.48c0 .17-.065.333-.18.453a.603.603 0 01-.435.187z"
    ></path>
  </svg>
);

export default CategoriesIcon;
