import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Country,
  CreateCountries,
  UpdateCountries,
} from "../../interfaces/country";
import { HttpRequest, RequestType } from "../../plugins/httpRequest";

interface ApiResponse<T> {
  data: T[];
  // other properties if necessary
}
export const getCountries = createAsyncThunk(
  "getCountries",
  async ({ user }: { user?: any }, { rejectWithValue }) => {
    try {
      const result = await HttpRequest<null, ApiResponse<Country>>({
        url: `Admin/CountriesView/GetAll`,
        method: RequestType.GET,
      });
      
      if(user.role === "SUPERADMIN") {
        return result
      }else if (user.role === "ADMIN") {
        const filteredCountries = result.data.filter((country) => user.managedCountries.includes(country.name));
        return {
          ...result,
          data: filteredCountries
        };
      }else if (user.role === "EDITOR") {
        const filteredCountries = result.data.filter((country) => user.managedCountries.includes(country.name));
        return {
          ...result,
          data: filteredCountries
        };
      }
      
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCountries = createAsyncThunk(
  "updateCountries",
  async ({ country }: { country: UpdateCountries }) => {
    const res = HttpRequest<UpdateCountries, Country>({
      url: `Admin/Countries/Update`,
      method: RequestType.POST,
      body: country,
    });

    return res;
  }
);

export const createCountries = createAsyncThunk(
  "createCountries",
  async function ({ country }: { country: CreateCountries }) {
    const res = HttpRequest<CreateCountries, Country>({
      url: `Admin/Countries/Add`,
      method: RequestType.POST,
      body: country,
    });

    return res;
  }
);

export const deleteCountries = createAsyncThunk(
  "deleteCountries",
  async function ({ countryId }: { countryId: string }) {
    const res = HttpRequest<null, null>({
      url: `Admin/Countries/Delete?id=${countryId}`,
      method: RequestType.POST,
    });

    return res;
  }
);
