const CloseIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10.09}
    height={10.09}
    {...props}
  >
    <g opacity={0.702}>
      <g data-name="icn-close">
        <path
          data-name="Path 9"
          d="m6.506 5.09 2.789-2.793A1 1 0 1 0 7.882.883L5.09 3.677 2.298.883A1 1 0 0 0 .884 2.297L3.677 5.09.884 7.882a1 1 0 0 0 .707 1.707.989.989 0 0 0 .707-.293l2.792-2.79 2.791 2.791a1 1 0 0 0 .71.292.981.981 0 0 0 .7-.293 1 1 0 0 0 0-1.416Z"
          fill={props.color ? props.color : "#5a5a5a"}
          stroke="rgba(0,0,0,0)"
        />
      </g>
      <path
        data-name="Color Overlay"
        d="M7.881 9.298 5.09 6.507l-2.793 2.79a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.416l2.794-2.793L.883 2.296A1 1 0 0 1 2.297.881L5.09 3.675 7.882.881a1 1 0 0 1 1.413 1.415L6.507 5.088 9.295 7.88a1 1 0 0 1 0 1.416.983.983 0 0 1-.7.292 1 1 0 0 1-.714-.29Z"
        fill={props.color ? props.color : "#5a5a5a"}
        stroke="rgba(0,0,0,0)"
      />
    </g>
  </svg>
);

export default CloseIcon;
