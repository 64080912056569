const ClientsIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={40}
    height={40}
    {...props}
  >
    <path
      fill={props.primarycolor ? "#ff9f27" : "#21252D"}
      d="M0 0H40V40H0z"
    ></path>
    <g
      fill={props.primarycolor ? "#fff" : "#778095"}
      clipPath="url(#clip0_209_139)"
    >
      <path
        d="M18.72 23.162c0 .482-.15.953-.431 1.354a2.537 2.537 0 01-1.15.898 2.68 2.68 0 01-1.478.139 2.602 2.602 0 01-1.311-.667 2.405 2.405 0 01-.7-1.249 2.33 2.33 0 01.145-1.408c.194-.446.522-.827.943-1.095.42-.267.916-.41 1.422-.41.68 0 1.33.256 1.81.714.48.457.75 1.077.75 1.724zM16.16 12.8c-.506 0-1.001.143-1.422.41-.421.269-.75.65-.943 1.095a2.33 2.33 0 00-.146 1.409c.1.472.343.907.7 1.248.359.34.815.573 1.312.667a2.68 2.68 0 001.479-.139 2.537 2.537 0 001.149-.898c.281-.4.431-.872.431-1.354a2.38 2.38 0 00-.75-1.724 2.626 2.626 0 00-1.81-.714zm7.68 7.924c-.506 0-1.001.143-1.422.41-.421.268-.75.65-.943 1.095a2.33 2.33 0 00-.146 1.408c.1.473.343.908.7 1.249.359.34.815.573 1.312.667a2.68 2.68 0 001.479-.139 2.537 2.537 0 001.149-.898c.281-.4.431-.872.431-1.354a2.38 2.38 0 00-.75-1.724 2.626 2.626 0 00-1.81-.714zm0-3.048c.506 0 1.001-.143 1.422-.41.421-.269.75-.65.943-1.095a2.33 2.33 0 00.146-1.409 2.405 2.405 0 00-.7-1.248 2.601 2.601 0 00-1.311-.667 2.68 2.68 0 00-1.48.138 2.537 2.537 0 00-1.148.898c-.282.401-.432.873-.432 1.355 0 .646.27 1.267.75 1.724.48.457 1.131.714 1.81.714z"
        opacity="0.2"
      ></path>
      <path d="M12.246 19.878a.614.614 0 00.862-.123 4 4 0 016.4 0 .616.616 0 00.984 0 4 4 0 016.4 0 .616.616 0 00.985-.74 5.22 5.22 0 00-2.107-1.666 3.077 3.077 0 10-4.15 0 5.173 5.173 0 00-1.615 1.1 5.174 5.174 0 00-1.616-1.1 3.077 3.077 0 10-4.149 0c-.84.36-1.57.936-2.117 1.668a.614.614 0 00.123.861zm11.447-6.645a1.847 1.847 0 110 3.693 1.847 1.847 0 010-3.693zm-7.385 0a1.847 1.847 0 110 3.693 1.847 1.847 0 010-3.693zm9.462 12.114a3.076 3.076 0 10-4.15 0 5.175 5.175 0 00-1.615 1.1 5.175 5.175 0 00-1.616-1.1 3.077 3.077 0 10-4.149 0c-.84.362-1.57.937-2.117 1.668a.614.614 0 10.985.739 4 4 0 016.4 0 .616.616 0 00.984 0 4 4 0 016.4 0 .616.616 0 00.985-.74 5.22 5.22 0 00-2.107-1.667zm-9.462-4.115a1.847 1.847 0 110 3.693 1.847 1.847 0 010-3.693zm7.385 0a1.847 1.847 0 110 3.693 1.847 1.847 0 010-3.693z"></path>
    </g>
  </svg>
);

export default ClientsIcon;
