import React, { useState } from "react";
import { InputAdornment, OutlinedInputProps, TextField } from "@mui/material";
import { Calendar } from "@mui/x-date-pickers/internals/components/icons";
import { DateTimePicker } from "@mui/x-date-pickers";

type DateTimePickerProps = {
  label: string;
  value: string;
  onChange: any;
};

const CardDateTimePicker: React.FC<DateTimePickerProps> = ({
  label,
  value,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <DateTimePicker
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      ampm={false}
      PaperProps={{
        sx: {
          "& .MuiPickersDay-root": {
            "&.Mui-selected": {
              backgroundColor: "#FF9F27",
            },
          },
          "& .MuiClock-root": {
            "& .MuiClock-clock": {
              "& .MuiClock-pin": {
                backgroundColor: "#FF9F27",
              },
              "& .MuiClockPointer-root": {
                backgroundColor: "#FF9F27",
                "& .MuiClockPointer-thumb": {
                  backgroundColor: "#FF9F27",
                  borderColor: "#FF9F27",
                },
              },
            },
          },
        },
      }}
      inputFormat="DD/MM/YYYY - HH:mm:ss"
      renderInput={(props) => (
        <TextField
          {...props}
          error={false}
          sx={{ flex: 1 }}
          variant="filled"
          onClick={(e) => setOpen(true)}
          InputProps={
            {
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Calendar />
                </InputAdornment>
              ),
            } as Partial<OutlinedInputProps>
          }
        />
      )}
      label={label}
      value={value}
      onChange={onChange}
    />
  );
};

export default CardDateTimePicker;
