import { FC } from "react";
import "./styles.css";

interface Props {
  height?: number;
}

const LoadingSpinner: FC<Props> = ({ height }) => {
  return (
    <div
      className="pos-center"
      style={{ height: height ? `${height}px` : "none" }}
    >
      <div className="loader"></div>
    </div>
  );
};

export default LoadingSpinner;
