import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { createCountries, deleteCountries, getCountries, updateCountries } from "./api";
export { getCountries };

export interface CountriesState {
  countries: any[];
  countryId: string;
  loading: boolean;
  error: any;
}

const initialState: CountriesState = {
  countries: [],
  countryId: "f88c8e76-6e6a-4859-ac8c-078b4aef0a05",
  loading: true,
  error: "",
};

export const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    setCountryId: (state, action) => {
      state.countryId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getCountries.fulfilled, (state, action: any) => {
        if (action.payload) {
          state.countries = action.payload.data;
        }
        state.loading = false;
      })
      .addCase(getCountries.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching countries data!";
        toast.error("Get countries failed!");
      });
      builder
      .addCase(updateCountries.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateCountries.fulfilled, (state, action) => {
        const index = state.countries.findIndex(
          (country) => country.id === action.payload.id
        );
        state.countries[index] = action.payload;
        state.loading = false;
        toast.success("Country updated!");
      })
      .addCase(updateCountries.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching cards data!";
      });

    builder
      .addCase(createCountries.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createCountries.fulfilled, (state, action) => {
        state.countries.push(action.payload);
        state.loading = false;
        toast.success("Country created!");
      })
      .addCase(createCountries.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching cards data!";
      });

      builder
      .addCase(deleteCountries.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteCountries.fulfilled, (state, action) => {
        state.countries.push(action.payload);
        state.loading = false;
        toast.error("Country deleted!");
      })
      .addCase(deleteCountries.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching cards data!";
      });

    
  },
});

export const { setCountryId } = countriesSlice.actions;

export default countriesSlice.reducer;
