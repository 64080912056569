const UserAccountIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <circle data-name="Ellipse 1056" cx={20} cy={20} r={20} fill="#ff9f27" />
    <g
      data-name="Group 29568"
      fill="none"
      stroke="#fff"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path d="M29.434 30.928a17.277 17.277 0 0 1-4.841.583h-9.209a17.116 17.116 0 0 1-4.819-.583c.338-3.991 4.436-7.137 9.424-7.137s9.107 3.146 9.445 7.137Z" />
      <path
        data-name="Vector"
        d="M25.483 13.984a5.495 5.495 0 1 1-5.494-5.495 5.5 5.5 0 0 1 5.494 5.495Z"
      />
    </g>
  </svg>
);

export default UserAccountIcon;
