import { Box } from "@mui/material";
import { useEffect } from "react";
import "./styles.css";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZGV2dGVzdC1sayIsImEiOiJjbDczaXQ5NTMxNnF3M3VzYjVlYzN2a3RnIn0.yUyWioWyo4NtoFyvTaJowQ";
// const geojson = {
//   type: "FeatureCollection",
// };

const Map = ({mapData}: any) => {
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: "map", // container ID
      style: "mapbox://styles/mapbox/streets-v11", // style URL
      center: mapData?.coords, // center
      zoom: mapData?.zoom,
      projection: { name: "mercator" },
    });

    // for (const marker of geojson.features) {
    //   Create a DOM element for each marker.
    //   const el = document.createElement('div');
    //   el.className = styles.marker;
    //   el.style.width = `10px`;
    //   el.style.height = `10px`;

    //   const subEl = document.createElement('div');
    //   subEl.className = styles.markerContent;
    //   subEl.innerText = marker.properties.message;
    //   subEl.onclick = () => {};
    //   el.appendChild(subEl);

    //   Add markers to the map.
    //   new mapboxgl.Marker(el).setLngLat(marker.geometry.coordinates).addTo(map);
    // }

    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: false,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: false,
      })
    );

    map.scrollZoom.disable();
  }, [mapData]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <div className="map-container" id="map" />
    </Box>
  );
};

export default Map;
