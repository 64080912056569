import { Box, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";

const GenderStats = ({ analyticsData }: any) => {
  const [confirmedGenderData, setConfirmedGenderData] = useState<any>([]);
  const [enhancedGenderData, setEnhancedGenderData] = useState<any>([]);

  useEffect(() => {
    const getGenderStats = () => {
      const confirmedStatsData = {
        man: [] as number[],
        woman: [] as number[],
      };
      const enhancedStatsData = {
        man: [] as number[],
        woman: [] as number[],
      };

      analyticsData?.forEach(
        (item: {
          role: string;
          level: number;
          genderName: string;
          count: number;
        }) => {
          if (item.role === "USER") {
            if (item.level === 1) {
              if (item.genderName === "Erkek") {
                confirmedStatsData.man.push(item.count);
              } else if (item.genderName === "Kadın") {
                confirmedStatsData.woman.push(item.count);
              }
            } else if (item.level === 2) {
              if (item.genderName === "Erkek") {
                enhancedStatsData.man.push(item.count);
              } else if (item.genderName === "Kadın") {
                enhancedStatsData.woman.push(item.count);
              }
            }
          }
        }
      );

      setConfirmedGenderData(confirmedStatsData);
      setEnhancedGenderData(enhancedStatsData);
    };

    getGenderStats();
  }, [analyticsData]);

  const dataConfirmed = [
    {
      id: "kadin",
      label: "Kadın O",
      value: confirmedGenderData?.woman?.length,
      color: "#c73487",
    },
    {
      id: "erkek",
      label: "Erkek O",
      value: confirmedGenderData?.man?.length,
      color: "#359fd1",
    },
  ];

  const filteredDataConfirmed = dataConfirmed.filter((item) => item.value > 0);

  const dataEnhanced = [
    {
      id: "kadin",
      label: "Kadın G",
      value: enhancedGenderData?.woman?.length,
      color: "#c73487",
    },
    {
      id: "erkek",
      label: "Erkek G",
      value: enhancedGenderData?.man?.length,
      color: "#359fd1",
    },
  ];

  const noData = [
    {
      id: "noData",
      label: "NoData",
      value: 1,
      color: "#dcdcdc",
    },
  ];

  const filteredDataEnhanced = dataEnhanced.filter((item) => item.value > 0);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
      }}
    >
      <Box bgcolor={"white"} sx={{ width: "100%" }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          py={2}
          borderBottom={0.5}
          borderColor={"#C2C2C2"}
        >
          <Typography>Profile Göre Cinsiyet Dağılımı</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Box p={1} sx={{width: "50%", display: "flex", justifyContent: "center", height: "270px", }} >
            {filteredDataConfirmed.length > 0 ? (
              <ResponsivePie
                data={filteredDataConfirmed}
                arcLabelsTextColor="#ffffff"
                margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
                innerRadius={0.5}
                padAngle={1.3}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 0.2]],
                }}
                enableArcLinkLabels={false}
                arcLinkLabelsTextColor="#333333"
                colors={{ datum: "data.color" }}
              />
            ) : (
              <ResponsivePie
                data={noData}
                arcLabelsTextColor="#ffffff"
                margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
                innerRadius={0.5}
                padAngle={1.3}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 0.2]],
                }}
                enableArcLinkLabels={false}
                arcLinkLabelsTextColor="#333333"
                colors={{ datum: "data.color" }}
              />
            )}
          </Box>
          <Box p={1} sx={{ width: "50%", display: "flex", justifyContent: "center", height: "270px", }}>
            {filteredDataEnhanced.length > 0 ? (
              <ResponsivePie
                data={filteredDataEnhanced}
                arcLabelsTextColor="#ffffff"
                margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
                innerRadius={0.5}
                padAngle={1.3}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 0.2]],
                }}
                enableArcLinkLabels={false}
                arcLinkLabelsTextColor="#333333"
                colors={{ datum: "data.color" }}
              />
            ) : (
              <ResponsivePie
                data={noData}
                arcLabelsTextColor="#ffffff"
                margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
                innerRadius={0.5}
                padAngle={1.3}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 0.2]],
                }}
                enableArcLinkLabels={false}
                arcLinkLabelsTextColor="#333333"
                colors={{ datum: "data.color" }}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
            marginBottom: "16px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div
              style={{
                width: "22px",
                height: "22px",
                backgroundColor: "#359fd1",
                borderRadius: "50%",
              }}
            ></div>
            <Typography>Erkek</Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div
              style={{
                width: "22px",
                height: "22px",
                backgroundColor: "#c73487",
                borderRadius: "50%",
              }}
            ></div>
            <Typography>Kadın</Typography>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default GenderStats;
