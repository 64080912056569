export const notificationsData = [
  {
    id: "1",
    content: "“Türkiye” kategorisine 3 yeni kart eklendi. Hemen oyla!",
    date: "12.10.2022",
    view: "All Users",
  },
  {
    id: "2",
    content:
      "“Starbucks” adında sponsorlu bir kategori eklendi. Profil oluştur, kartlarını takip et, daha çok puan al. Hemen oyla!",
    date: "12.10.2022",
    view: "All Users",
  },
  {
    id: "3",
    content: "“Elon Musk” kategorisine 3 yeni kart eklendi. Hemen oyla!",
    date: "12.10.2022",
    view: "Registered Users",
  },
  {
    id: "4",
    content:
      "Dilediğin kategori hakkında anket yapabileceğini biliyor musun? Detaylı bilgi",
    date: "12.10.2022",
    view: "All Users",
  },
  {
    id: "5",
    content: "SVAYP’a hoş geldin! Haydi hemen oyla!",
    date: "12.10.2022",
    view: "Registered Users",
  },
];
