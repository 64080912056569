import { Box, styled } from "@mui/material";

export const LoginWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  overflow: hidden;

  padding: 32px;
`;

export const LoginCard = styled(Box)`
  display: flex;
  flex-direction: column;

  gap: 32px;
  padding-bottom: 46px;
`;

export const BottomTextWrapper = styled(Box)`
  position: absolute;
  bottom: 0;
  border-top: 0.5px solid #c2c2c2;
  padding: 12px 0px;
  width: calc(100% - 64px);
  display: flex;
  justify-content: space-between;
`;
