import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getCardDynamic } from "./api";
import { DynamicCard, DynamicCardItem } from "../../interfaces/dynamic";
import { UserRole } from "../../interfaces/user";
export interface AnalyticsState {
  cards: DynamicCard[];
  loading: boolean;
  error: any;
}

const initialState: AnalyticsState = {
  cards: [],
  loading: false,
  error: "",
};

export const analyticsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    /* incrementSkip: (state) => {
      state.skip += 1;
    },
    decrementSkip: (state) => {
      state.skip -= 1;
    },
    resetSkip: (state) => {
      state.skip = 0;
    },
    setSkip: (state, action) => {
      state.skip = action.payload;
    }, */
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCardDynamic.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getCardDynamic.fulfilled, (state, action) => {
        state.loading = false;
        let output: DynamicCard[] = [];

        action.payload.data.forEach(function (item) {
          var existing = output.filter(function (v, i) {
            return v.cardId === item.cardId;
          });
          const safeLevel = item.level === null ? 0 : item.level; //   used it for level could be null
          if (existing.length) {
            var existingIndex = output.indexOf(existing[0]);

            output[existingIndex].dynamics[safeLevel].count =
              output[existingIndex].dynamics[safeLevel].count + item.count;

            output[existingIndex].dynamics[safeLevel].optionleftstat =
              output[existingIndex].dynamics[safeLevel].optionleftstat +
              item.optionleftstat;

            output[existingIndex].dynamics[safeLevel].optionrightstat =
              output[existingIndex].dynamics[safeLevel].optionrightstat +
              item.optionrightstat;

            output[existingIndex].dynamics[safeLevel].optiontopstat =
              output[existingIndex].dynamics[safeLevel].optiontopstat +
              item.optiontopstat;
          } else {
            const dynamics: DynamicCardItem[] = [0, 1, 2].map((level) => ({
              role: level === 0 ? UserRole.ANON : UserRole.USER,
              level: level,
              count: 0,
              optionleftstat: 0,
              optionrightstat: 0,
              optiontopstat: 0,
            }));
            dynamics[safeLevel] = {
              role: item.role,
              level: safeLevel,
              count: item.count,
              optionleftstat: item.optionleftstat,
              optionrightstat: item.optionrightstat,
              optiontopstat: item.optiontopstat,
            };
            const newItem: DynamicCard = {
              cardId: item.cardId,
              cardTitle: item.cardTitle,
              createdAt: item.createdAt,
              dynamics,
            };
            output.push(newItem);
          }
        });
        state.cards = output;
      })
      .addCase(getCardDynamic.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching cards data!";
        toast.error("Get cards failed!");
      });
  },
});

//export const { incrementSkip, decrementSkip, resetSkip, setSkip } =
//  cardsSlice.actions;

export default analyticsSlice.reducer;
