const Checked = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27} height={27} {...props}>
    <g data-name="Icon ionic-ios-checkbox-outline">
      <path
        data-name="Path 41927"
        d="M24.75 0H2.25A2.248 2.248 0 0 0 0 2.25v22.5A2.248 2.248 0 0 0 2.25 27h22.5A2.248 2.248 0 0 0 27 24.75V2.25A2.248 2.248 0 0 0 24.75 0Zm.281 24.469a.564.564 0 0 1-.562.563H2.531a.564.564 0 0 1-.562-.562V2.531a.564.564 0 0 1 .563-.562h21.937a.564.564 0 0 1 .563.563Z"
        fill="#c2c2c2"
      />
      <path
        data-name="Path 41928"
        d="M21.066 9.063 19.829 7.79a.266.266 0 0 0-.2-.084.255.255 0 0 0-.2.084l-8.578 8.641-3.117-3.121a.272.272 0 0 0-.394 0l-1.252 1.252a.28.28 0 0 0 0 .4l3.939 3.938a1.245 1.245 0 0 0 .823.4 1.3 1.3 0 0 0 .816-.387h.007l9.4-9.45a.3.3 0 0 0-.007-.4Z"
        fill="#34c759"
      />
    </g>
  </svg>
);

export default Checked;
