import { Box, styled } from "@mui/material";
import { IMG_URL } from "../../../../constants";

export const CardPreview = styled(Box)<{ imgsrc: string }>`
  /* background: ${(props) =>
    `linear-gradient( #00000040 100%, #00000040 100%), url(${props.imgsrc})`}; */
  background: ${({ imgsrc }) =>
    `url(${imgsrc.startsWith("data") ? imgsrc : IMG_URL + imgsrc})`};
  background-size: 100% 100%;

  width: 100%;
  height: 100%;

  border-radius: 12px;
  position: relative;
`;

export const CardHeader = styled(Box)`
  position: absolute;
  width: 100%;
  height: 178px;
  border-radius: 12px 12px 0 0;
  background: transparent;
  background: linear-gradient(180deg, #000 0%, #000 10%, transparent 100%);
`;

export const CardFooter = styled(Box)`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 318px;
  border-radius: 0 0 12px 12px;
  background: transparent;
  background: linear-gradient(0deg, #000 0%, #000 0%, transparent 100%);
`;
