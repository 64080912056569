import {
  Box,
  Button,
  inputLabelClasses,
  Modal,
  OutlinedInputProps,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import CloseIcon from "../../../../assets/categories/CloseIcon";
import SwitchComp from "../../../../components/switchComp";
import { useAppDispatch } from "../../../../services";
import { Country } from "../../../../interfaces/country";
import {
  createCountries,
  deleteCountries,
  updateCountries,
} from "../../../../services/countries/api";
import TimezoneSelect from "../timeZoneSelect";

type CountryModalProps = {
  open: boolean;
  onClose: any;
  country?: Country;
};

const CountryModal: FC<CountryModalProps> = ({ open, onClose, country }) => {
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState(false);

  const [countryCode, setCountryCode] = useState(country?.code || "");
  const [countryName, setCountryName] = useState(country?.name);
  const [countryId, setCountryId] = useState(country?.id);

  const [selectedTimezone, setSelectedTimezone] = useState('0');


  useEffect(() => {
    setCountryName(country?.name);
    setCountryCode(country?.code || "");
    setCountryId(country?.id);
    setChecked(country?.status === "ACTIVE");
  }, [country]);

  const deleteCountry = async () => {
    const confirmed = window.confirm(
      "Bu işlemi geri alamazsınız silmek istediğinizden emin misiniz?"
    );
    if (confirmed && country) {
      dispatch(
        deleteCountries({
          countryId: countryId || country.id,
        })
      )
        .then(() => {})
        .finally(() => {
          onClose();
          window.location.reload();
        });
    }
  };

  const saveCountry = async () => {
    if (country) {
      dispatch(
        updateCountries({
          country: {
            id: country.id,
            name: countryName!,
            code: countryCode!,
            status: checked ? "ACTIVE" : "PASSIVE",
          },
        })
      )
        .then(() => {})
        .finally(() => {
          onClose();
          window.location.reload();
        });
    } else {
      dispatch(
        createCountries({
          country: {
            name: countryName!,
            code: countryCode!,
            status: checked ? "ACTIVE" : "PASSIVE",
          },
        })
      )
        .then(() => {})
        .finally(() => {
          onClose();
          window.location.reload();
        });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "591px",
          height: "383px",
          bgcolor: "background.paper",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          onClick={onClose}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            cursor: "pointer",
            padding: "12px 16px",
          }}
        >
          <CloseIcon />
        </Box>
        <Typography variant="paragraph" color="#000">
          Add Country
        </Typography>
        <Typography variant="paragraph" m="16px 0px">
          Ülke kodunu{" "}
          <a
            style={{ color: "#FF9F27", textDecoration: "none" }}
            href="https://apps.timwhitlock.info/emoji/tables/iso3166"
            target="_blank"
            rel="noreferrer"
          >
            bu adresteki
          </a>{" "}
          ISO koduna göre ekleyin.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              flex: 1,
              flexDirection: "column",
              height: "168px",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "16px",
              }}
            >
              <TextField
                sx={{
                  width: "100%",
                  mb: "16px",
                }}
                id="countryName"
                label="Country Name"
                variant="filled"
                onChange={(e) => setCountryName(e.target.value)}
                value={countryName}
                InputProps={
                  {
                    disableUnderline: true,
                  } as Partial<OutlinedInputProps>
                }
                InputLabelProps={{
                  sx: {
                    [`&.${inputLabelClasses.shrink}`]: {
                      color: "#c2c2c2",
                    },
                  },
                }}
              />
              <TextField
                sx={{
                  width: "100%",
                  mb: "16px",
                }}
                id="countryCode"
                label="Country Code"
                variant="filled"
                onChange={(e) => setCountryCode(e.target.value)}
                value={countryCode}
                InputProps={
                  {
                    disableUnderline: true,
                  } as Partial<OutlinedInputProps>
                }
                InputLabelProps={{
                  sx: {
                    [`&.${inputLabelClasses.shrink}`]: {
                      color: "#c2c2c2",
                    },
                  },
                }}
              />
            </Box>

      
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "24px"
              }}
            >
                  <Box sx={{
                    width: "100%",
                  }}>
          <TimezoneSelect selectedTimezone={selectedTimezone} setSelectedTimezone={setSelectedTimezone} />
            </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Typography variant="paragraph">
                  {checked ? "Active" : "Passive"}
                </Typography>
                <SwitchComp checked={checked} setChecked={setChecked} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Button variant="contained" onClick={saveCountry}>
          <Typography variant="buttonText">
            {country ? "Update" : "Save"}
          </Typography>
        </Button>

        {country && (
                <Button
                variant="contained"
                onClick={deleteCountry}
                sx={{
                  backgroundColor: "#FF0000",
                  color: "#fff",
                  height: "40px",
                  marginTop: "16px",
                  "&:hover": {
                    backgroundColor: "#FF0000",
                  },
                }}
              >
                <Typography variant="buttonText">Delete Country</Typography>
              </Button>
              )}
      </Box>
    </Modal>
  );
};

export default CountryModal;
