import * as React from "react"
const AndroidIcon = (props ?: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={18}
    height={18}
    viewBox="-146 129 218 256"
    {...props}
    fill="#fff"
  >
    <path d="m-2.9 150.4 2.8-4.2 2.8-4.1 6.2-9.3c.8-1.1.5-2.7-.7-3.4-1.1-.8-2.7-.5-3.4.7l-6.6 9.9-2.8 4.2-2.8 4.2c-9-3.5-18.9-5.4-29.5-5.4-10.5 0-20.5 1.9-29.5 5.4l-2.8-4.2-2.8-4.2-6.6-9.9c-.8-1.1-2.3-1.4-3.4-.7-1.1.8-1.4 2.3-.7 3.4l6.2 9.3 2.8 4.1 2.8 4.2c-21 9.8-35.3 28.3-35.3 49.6H32.5c-.1-21.3-14.3-39.8-35.4-49.6zm-63.8 29.7c-4.1 0-7.4-3.3-7.4-7.4 0-4.1 3.3-7.4 7.4-7.4 4.1 0 7.4 3.3 7.4 7.4s-3.3 7.4-7.4 7.4zm59.4 0c-4.1 0-7.4-3.3-7.4-7.4 0-4.1 3.3-7.4 7.4-7.4 4.1 0 7.4 3.3 7.4 7.4.1 4.1-3.2 7.4-7.4 7.4zM-105.3 209.8l-1.1.1v109c0 8.7 7 15.7 15.7 15.7h11.3c-.4 1.3-.6 2.7-.6 4.1V370.1c0 8.2 6.7 14.9 14.9 14.9s14.9-6.7 14.9-14.9V338.7c0-1.4-.2-2.8-.6-4.1h27.6c-.4 1.3-.6 2.7-.6 4.1V370.1c0 8.2 6.7 14.9 14.9 14.9S6 378.3 6 370.1V338.7c0-1.4-.2-2.8-.6-4.1h11.3c8.7 0 15.7-7 15.7-15.7V209.8h-137.7zM-131.1 209.9c-8.2 0-14.9 6.7-14.9 14.9v63.6c0 8.2 6.7 14.9 14.9 14.9s14.9-6.7 14.9-14.9v-63.6c-.1-8.3-6.7-14.9-14.9-14.9zM57.2 209.9c-8.2 0-14.9 6.7-14.9 14.9v63.6c0 8.2 6.7 14.9 14.9 14.9s14.9-6.7 14.9-14.9v-63.6c-.1-8.3-6.7-14.9-14.9-14.9z" />
  </svg>
)
export default AndroidIcon
