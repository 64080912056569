import React, { FC, useState } from "react";

import {
  Box,
  Button,
  Chip,
  inputLabelClasses,
  OutlinedInputProps,
  Stack,
  TextField,
} from "@mui/material";
import { useKey } from "../../../../utils/useKey";
import { useTranslation } from 'react-i18next';
import { AutoAwesome } from "@mui/icons-material";

type TagsInputProps = {
  tags: string[];
  setTags: any;
  title?: string;
};

const TagsInput: FC<TagsInputProps> = ({ tags, setTags, title }) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestedKeywords, setSuggestedKeywords] = useState<string[]>([]);
  
  const titleWords: any = title?.split(" ")
  .map((word: string) => word.replace(/[^\wğüşıöçĞÜŞİÖÇ\s]|_/g, "").toLowerCase())
  .filter((word: string) => word.trim().length > 0);
const suggestKeywords = () => {
  setSuggestedKeywords(titleWords);
};


  const handleKeyDown = (event: any) => {
    if (event.target.id !== "tags") return;
    if (event.key === "Enter") {
      setInputValue("");
      const newSelectedTags = [...tags];
      const duplicatedValues = newSelectedTags.indexOf(
        event.target.value.trim()
      );

      if (duplicatedValues !== -1) {
        setInputValue("");
        return;
      }
      if (!event.target.value.replace(/\s/g, "").length) return;

      newSelectedTags.push(event.target.value.trim());
      setTags(newSelectedTags);
      setInputValue("");
    }
    if (tags.length && !inputValue.length && event.key === "Backspace") {
      setTags(tags.slice(0, tags.length - 1));
    }
  };

  const handleDelete = (item: any) => () => {
    const newSelectedTags = [...tags];
    newSelectedTags.splice(newSelectedTags.indexOf(item), 1);
    setTags(newSelectedTags);
  };

  useKey("Enter", handleKeyDown);

  const { t } = useTranslation(["cards"]);

  return (
    <>

    <TextField
      id="tags"
      label={t('tags')}
      variant="filled"
      value={inputValue}
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
      InputProps={
        {
          sx: {
            display: "flex",
            flexDirection: "column",
            "& .MuiFilledInput-input": {
              paddingTop: tags.length ? "6px" : "25px",
              paddingLeft: "12px",
            },
            paddingLeft: "12px",
            paddingRight: "12px",
          },
          startAdornment: !!tags.length && (
            <Stack
              sx={{
                mt: "28px",
                mr: "1px",
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "flex-start",
                gap: "6px",
              }}
              direction="row"
              // spacing={1}
            >
              {tags.map((item) => (
                <Chip
                  key={item}
                  tabIndex={-1}
                  label={item}
                  onDelete={handleDelete(item)}
                  size="small"
                />
              ))}
            </Stack>
          ),
          endAdornment: (
            <Button variant="text" onClick={suggestKeywords} style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", color: "white", width: "40px", height: "40px", minWidth: "40px" }}>
              <AutoAwesome fontSize="small" />
            </Button>
          ),
          onChange: (event: any) => setInputValue(event.target.value),
          disableUnderline: true,
        } as Partial<OutlinedInputProps>
      }
      InputLabelProps={{
        sx: {
          [`&.${inputLabelClasses.shrink}`]: {
            color: "#c2c2c2",
          },
        },
      }}
    />
    {title && (
    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "6px", mt: "10px" }}>
    {suggestedKeywords.map((item) => (
      <Chip
        key={item}
        tabIndex={-1}
        label={item}
        onClick={() => {
          setInputValue(item);
          const newSelectedTags = [...tags];
          const duplicatedValues = newSelectedTags.indexOf(item);

          if (duplicatedValues !== -1) {
            setInputValue("");
            return;
          }
          if (!item.replace(/\s/g, "").length) return;

          newSelectedTags.push(item);
          setTags(newSelectedTags);
          setInputValue("");
        }}
        size="small"
        style={{ cursor: "pointer" }}
      />
    ))}
    </Box>
    )}
    </>
  );
};

export default TagsInput;
