import { Box, styled } from "@mui/material";

export const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 120px;
`;

export const CardsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 22px;

  margin-bottom: 80px;
`;

export const PassiveCardsWrapper = styled(CardsWrapper)`
  min-width: 100%;
  gap: 32px 16px;
`;
