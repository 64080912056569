/* eslint-disable no-shadow */
import { createTheme, ThemeOptions } from "@mui/material";

interface ITheme {
  primary: string;
  primaryTextColor: string;
  black: string;
  white: string;
  orange: string;
}

interface ITypography {
  title1: {};
  subTitle1: {};
  paragraph: {};
  subContent: {};
  cardTitle: {};
  cardNumber: {};
  cardContent: {};
  cardCategoryTitle: {};
  buttonText: {};
  clickable: {};
}

interface IPalette {
  paper: true;
}

declare module "@mui/material" {
  interface Theme extends ITheme {}
  interface ThemeOptions extends ITheme {}
  interface TypographyPropsVariantOverrides extends ITypography {}
  interface ButtonPropsColorOverrides extends IPalette {}
}

export const AppColors: ITheme = {
  primary: "#F9F9FF",
  primaryTextColor: "#5A5A5A",
  black: "#000",
  white: "#fff",
  orange: "#FF9F27",
};

const commonTheme = {
  ...AppColors,

  palette: {
    label: "#c2c2c2",
    // primary: {
    //   main: AppColors.primary,
    // },
    // secondary: {
    //   main: AppColors.primaryLight,
    //   blue: AppColors.blue,
    // },
    // background: {
    //   default: AppColors.primary,
    //   paper: AppColors.paper,
    //   darkpaper: "#21212E",
    //   button: AppColors.primaryTextColor,
    // },
    // paper: {
    //   default: AppColors.paper,
    //   dark: "#21212E",
    // },
  },

  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: "none",
    },
    title1: {
      fontWeight: 500,
      fontSize: "1.75rem", // 28px
      lineHeight: "2.25rem",
      color: AppColors.black,
    },
    subTitle1: {
      fontWeight: 400,
      fontSize: "1.125rem", // 18px
      lineHeight: "1.5rem",
      color: AppColors.primaryTextColor,
    },
    paragraph: {
      fontWeight: 400,
      fontSize: "0.875rem", // 14px
      lineHeight: "1.5rem",
      color: AppColors.primaryTextColor,
    },
    subContent: {
      fontWeight: 400,
      fontSize: "0.75rem", // 12 px
      lineHeight: "1rem",
      color: AppColors.primaryTextColor,
    },
    cardTitle: {
      fontWeight: 600,
      fontSize: "1.375rem", // 22px
      lineHeight: "1.75rem",
      color: AppColors.white,
    },
    cardNumber: {
      fontWeight: 500,
      fontSize: "1.5rem", // 24px
      lineHeight: "2rem",
      color: AppColors.white,
    },
    cardContent: {
      fontWeight: 400,
      fontSize: "0.688rem", // 11 px
      lineHeight: "1rem",
      color: AppColors.white,
    },
    cardCategoryTitle: {
      fontWeight: 700,
      fontSize: "0.875rem", // 14px
      lineHeight: "1.5rem",
      color: AppColors.white,
    },
    buttonText: {
      fontWeight: 400,
      fontSize: "0.875rem", // 14px
      lineHeight: "1.5rem",
      color: AppColors.white,
    },
    clickable: {
      fontWeight: 400,
      fontSize: "0.875rem", // 14px
      lineHeight: "1.5rem",
      color: AppColors.orange,
    },
  },

  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "3px",
          border: "1px solid #c2c2c2",
          background: "transparent",
          display: "flex",
          alignContent: "center",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&.Mui-focused": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "#c2c2c2",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          background: AppColors.orange,
          height: "2.25rem",
          boxShadow: "none",
          "&:hover": {
            background: AppColors.orange,
            boxShadow: "none",
          },
          "&.MuiButton-outlined": {
            background: AppColors.white,
            border: `2px solid ${AppColors.orange}`,
            borderRadius: "4px",
            "*": {
              color: AppColors.orange,
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          margin: "0px",
          padding: "0px",
          paddingRight: "12px",
          "&:hover": {
            background: "transparent",
          },
          "&.Mui-checked": {
            color: "white",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          margin: "0px",
          padding: "0px",
          color: "transparent",
          "&:hover": {
            background: "transparent",
          },
        },
      },
    },
  },
} as ThemeOptions;

const theme = createTheme(commonTheme);

export default theme;
