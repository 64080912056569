import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import MeatballsMenuIcon from "../../assets/cards/MeatballsMenuIcon";
import CustomTable, { Column } from "../../components/customTable";
import Layout from "../../components/layout";
import SwitchComp from "../../components/switchComp";
import {
  User,
  UserLevel,
  UserLevelText,
  UserRole,
} from "../../interfaces/user";
import { useAppDispatch, useAppSelector } from "../../services";
import { getUsers, updateUser } from "../../services/usersSlice/api";
import { numberFormat } from "../../utils/helperFunctions";
import usePagination from "../../utils/usePagination";
import { Header } from "./styles";

const PER_PAGE = 8;

const Users = () => {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [userRole, setUserRole] = useState<UserRole>(UserRole.ALLUSERS);
  const [userLevel, setUserLevel] = useState<UserLevel>(UserLevel.ALLLEVEL);

  const { users } = useAppSelector((state) => state.users);
  /* const handleData = (id: string) => {
    const index = data.findIndex((item: any) => item.id === id);
    const obj = data[index];
    data.splice(index, 1, { ...obj, status: !obj.status });
    setData([...data]);
  }; */
  const { cities } = useAppSelector((state) => state.cities);
  const { countryId, countries } = useAppSelector((state) => state.countries);

  useEffect(() => {
    dispatch(
      getUsers({
        page,
        pageSize: PER_PAGE,
        role: userRole,
      })
    );
  }, [page, dispatch, userRole]);

  useEffect(() => {
    setPage(1);
    dispatch(
      getUsers({
        page,
        pageSize: PER_PAGE,
        level: userLevel === -1 ? undefined : userLevel,
      })
    );
  }, [countryId, userRole, userLevel, dispatch, page]);

  const count = Math.ceil(users.length / PER_PAGE);
  const _DATA = usePagination<User>(users.data, PER_PAGE);

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  const columns: Column<User>[] = [
    {
      id: "name",
      label: "NAME",
      width: "15%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status === "ACTIVE" ? "#000" : "#c2c2c2"}
        >
          {item.firstName
            ? `${item.firstName} ${item.lastName} `
            : "Anonymous User"}
        </Typography>
      ),
    },
    {
      id: "phone",
      label: "PHONE",
      width: "15%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status === "ACTIVE" ? "#000" : "#c2c2c2"}
        >
          {item.phone ? `+${item.phoneCountryCode} ${item.phone.replace(/(?<=.{3}).(?=.{2})/g, '*')}` : "N/A"}
        </Typography>
      ),
    },
    {
      id: "email",
      label: "E-MAIL",
      width: "20%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status === "ACTIVE" ? "#000" : "#c2c2c2"}
        >
          {item.email ? item.email.replace(/(?<=.{2}).(?=.{10})/g, '*') : "N/A"}
        </Typography>
      ),
    },
    {
      id: "registeredDate",
      label: "REGISTERED DATE",
      width: "15%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status === "ACTIVE" ? "#000" : "#c2c2c2"}
        >
          {new Date(item.registeredDate).toLocaleDateString()}
        </Typography>
      ),
    },
    {
      id: "userLevel",
      label: "USER TYPE",
      width: "10%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status === "ACTIVE" ? "#000" : "#c2c2c2"}
        >
          {UserLevelText[item.level]}
        </Typography>
      ),
    },
    {
      id: "region",
      label: "REGION",
      width: "10%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status === "ACTIVE" ? "#000" : "#c2c2c2"}
        >
          {item.cityId
            ? cities.find((city) => city.id === item.cityId)?.name
            : "N/A"}
        </Typography>
      ),
    },
    {
      id: "svaypPoints",
      label: "SVAYP POINTS",
      width: "10%",
      render: (item) => (
        <Typography
          variant="paragraph"
          color={item.status === "ACTIVE" ? "#000" : "#c2c2c2"}
        >
          {item.points}
        </Typography>
      ),
    },
    {
      id: "status",
      label: "STATUS",
      width: "10%",
      render: (item) => (
        <SwitchComp
          checked={item.status === "ACTIVE"}
          setChecked={() => {
            dispatch(
              updateUser({
                user: {
                  ...item,
                  status: item.status === "ACTIVE" ? "PASSIVE" : "ACTIVE",
                },
              })
            );
          }}
        />
      ),
    },
    {
      id: "menu",
      label: "",
      width: "10%",
      render: (item) => <MeatballsMenuIcon />,
    },
  ];

  return (
    <Layout>
      <Header>
        <Box>
          <Typography variant="paragraph">Filters: </Typography>
          {/*  <FormControl sx={{ width: "150px", ml: "16px" }}>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={userRole}
              onChange={(e: any) => {
                setUserRole(e.target.value);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ height: "36px", fontSize: "14px" }}
            >
              <MenuItem value={""}>All Users</MenuItem>
              <MenuItem value={UserRole.ANON}>Anon</MenuItem>
              <MenuItem value={UserRole.EDITOR}>Editor</MenuItem>
              <MenuItem value={UserRole.USER}>User</MenuItem>
              <MenuItem value={UserRole.ADMIN}>Admin</MenuItem>
              <MenuItem value={UserRole.SUPERADMIN}>Super Admin</MenuItem>
            </Select>
          </FormControl> */}
          <FormControl sx={{ width: "150px", ml: "16px" }}>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={userLevel}
              onChange={(e: any) => setUserLevel(e.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ height: "36px", fontSize: "14px" }}
            >
              <MenuItem value={UserLevel.ALLLEVEL}>All User Type</MenuItem>
              <MenuItem value={UserLevel.VERIFIED}>Kayıtlı Profil</MenuItem>
              <MenuItem value={UserLevel.ADVANCED}>Gelişmiş Profil</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            showFirstButton
            showLastButton
          />
        </Box>
      </Header>
      <Typography variant="body1">{`${
        countries?.find((country) => country.id === countryId)?.name
      } bölgesinde kayıtlı kullanıcı sayısı "${numberFormat(
        users.length
      )}"`}</Typography>
      <CustomTable
        title="Registered Users"
        columns={columns}
        data={_DATA.currentData()}
        noDataMessage={"No data found"}
      />
    </Layout>
  );
};

export default Users;
