const SvaypLogoSmall = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <g data-name="Group 29568">
      <path data-name="Rectangle 713" fill="#272b35" d="M0 0h64v64H0z" />
      <g data-name="Group 29567">
        <path
          d="m18.943 43.521-6.974-23.042h7.771l3.68 16.622h.127l3.68-16.622h7.442l-6.976 23.039Z"
          fill="#5a5a5a"
        />
        <path
          data-name="Vector"
          d="m45.054 20.479 6.976 23.039h-7.772L40.58 26.899h-.13l-3.677 16.619H29.33l6.966-23.039Z"
          fill="#c2c2c2"
        />
      </g>
    </g>
  </svg>
);

export default SvaypLogoSmall;
