const ActivityLogsIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={40}
    height={40}
    {...props}
  >
    <path
      fill={props.primarycolor ? "#ff9f27" : "#21252D"}
      d="M0 0H40V40H0z"
    ></path>
    <g
      fill={props.primarycolor ? "#fff" : "#778095"}
      clipPath="url(#clip0_209_141)"
    >
      <path
        d="M26.391 15.042l-2.163 11.7a.567.567 0 01-.447.445.613.613 0 01-.227.004L14.08 25.6a.585.585 0 01-.375-.227.533.533 0 01-.097-.414l2.164-11.7a.567.567 0 01.447-.445.613.613 0 01.227-.005l9.472 1.593a.585.585 0 01.376.227c.088.12.123.27.096.414z"
        opacity="0.2"
      ></path>
      <path d="M26.207 13.66l-9.768-1.643a1.247 1.247 0 00-.897.19c-.26.174-.438.439-.493.737l-2.23 12.072a1.089 1.089 0 00.199.854c.09.123.205.228.338.308.133.081.282.136.437.162l9.768 1.643a1.258 1.258 0 00.897-.19c.13-.086.24-.196.324-.323.084-.127.142-.268.17-.416l2.23-12.072a1.1 1.1 0 00-.2-.853 1.207 1.207 0 00-.775-.469zm-2.44 13.197L14 25.214l2.231-12.072 9.768 1.643-2.23 12.072zM17.1 15.03a.57.57 0 01.247-.369.624.624 0 01.449-.094l6.224 1.046c.148.025.28.101.372.215a.553.553 0 01-.07.77.616.616 0 01-.511.14l-6.225-1.046a.604.604 0 01-.387-.236.55.55 0 01-.099-.426zm-.415 2.251a.555.555 0 01.247-.369.615.615 0 01.448-.095l6.225 1.048c.149.023.283.1.375.213a.553.553 0 01-.071.774.617.617 0 01-.515.138l-6.224-1.046a.603.603 0 01-.387-.236.55.55 0 01-.098-.427zm-.416 2.25a.57.57 0 01.247-.366.624.624 0 01.448-.094l3.11.52c.148.025.281.102.373.215a.552.552 0 01-.07.77.617.617 0 01-.51.142l-3.113-.524a.603.603 0 01-.386-.236.55.55 0 01-.1-.426z"></path>
    </g>
  </svg>
);

export default ActivityLogsIcon;
