const NotificationsIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={40}
    height={40}
    {...props}
  >
    <path
      fill={props.primarycolor ? "#ff9f27" : "#21252D"}
      d="M0 0H40V40H0z"
    ></path>
    <g
      fill={props.primarycolor ? "#fff" : "#778095"}
      clipPath="url(#clip0_209_140)"
    >
      <path
        d="M25.82 24.8H14.18a.576.576 0 01-.502-.296.594.594 0 01.001-.588c.48-.84 1.083-2.372 1.083-5.01a5.34 5.34 0 011.534-3.752A5.205 5.205 0 0120 13.6c1.39 0 2.722.559 3.705 1.554a5.34 5.34 0 011.534 3.751c0 2.64.604 4.17 1.084 5.01a.595.595 0 01-.212.805.577.577 0 01-.29.08z"
        opacity="0.2"
      ></path>
      <path d="M26.89 15.877a.585.585 0 01-.438.037.572.572 0 01-.336-.278 6.672 6.672 0 00-2.401-2.597.568.568 0 01-.254-.351.55.55 0 01.074-.425.567.567 0 01.36-.25.587.587 0 01.433.074 7.901 7.901 0 012.808 3.031c.07.133.083.287.037.43a.566.566 0 01-.283.329zm-13.516.063a.582.582 0 00.51-.304 6.674 6.674 0 012.402-2.597.554.554 0 00.18-.777.585.585 0 00-.793-.176 7.9 7.9 0 00-2.809 3.033.553.553 0 00.229.749.583.583 0 00.281.072zm13.359 7.313a1.108 1.108 0 01.004 1.128c-.1.171-.245.314-.42.413-.175.1-.373.152-.575.152h-2.93a2.805 2.805 0 01-.996 1.619A2.907 2.907 0 0120 27.2a2.907 2.907 0 01-1.817-.635 2.805 2.805 0 01-.997-1.62h-2.929c-.201 0-.4-.052-.574-.151-.175-.1-.32-.242-.42-.414a1.109 1.109 0 01.004-1.127c.647-1.094.99-2.65.99-4.498 0-1.493.605-2.925 1.682-3.98A5.802 5.802 0 0120 13.125c1.523 0 2.984.593 4.06 1.649a5.572 5.572 0 011.683 3.98c0 1.848.343 3.403.991 4.498zm-5.11 1.693h-3.248c.12.329.34.613.63.815a1.747 1.747 0 001.988 0c.29-.202.51-.486.63-.815zm4.119-1.126c-.764-1.285-1.149-2.99-1.149-5.066 0-1.194-.484-2.339-1.345-3.184a4.642 4.642 0 00-3.249-1.318 4.642 4.642 0 00-3.249 1.318 4.458 4.458 0 00-1.345 3.184c0 2.077-.386 3.781-1.149 5.066h11.486z"></path>
    </g>
  </svg>
);

export default NotificationsIcon;
