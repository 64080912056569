import { Box, styled, Typography } from "@mui/material";

export const CardWrapper = styled(Box)<{ expanded: string }>`
  position: relative;
  width: 100%;
  height: ${({ expanded }) => (expanded === "true" ? "175px" : "155px")};
  padding: 10px 20px;
  color: #fff;
`;

export const Title = styled(Typography)<{ voteCard: boolean }>`
  font-size: ${({ voteCard }) => (voteCard ? "14px" : "1.375rem")};
  font-weight: ${({ voteCard }) => (voteCard ? "400" : "600")};
  color: ${({ voteCard }) => (voteCard ? "black" : "inherit")};
  
  @media (min-width: 1200px) {
    font-size: ${({ voteCard }) => (voteCard ? "14px" : "1.1rem")};
  }
`;

export const TinyButtonWrapper = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 8px;
  right: 8px;
  gap: 8px;
`;

export const TinyButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
`;

export const UpContentWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-top: 8px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 999px;
    object-fit: cover;
  }
`;

export const UpContentLeft = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
`;

export const DownContentWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  margin-top: 8px;
  justify-content: space-between;
`;

export const CardFooter = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-left: 20px;
  padding-right: 16px;

  width: 100%;
  color: #fff;
`;
