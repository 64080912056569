import { useEffect, useState } from "react";
import NumberOfUsers from "../../../components/dashboardCards/numberOfUsers/index";
import UserIcon from "../../../assets/dashboard/UserIcon";
import { useTranslation } from "react-i18next";

const AnonCard = ({ usersData }: any) => {
  const { t } = useTranslation(["dashboard"]);

  const [anonUserCount, setAnonUserCount] = useState<number>(0);
  const [registeredThisWeekCount, setRegisteredThisWeekCount] =
    useState<number>(0);
  const [registeredThisMonthCount, setRegisteredThisMonthCount] =
    useState<number>(0);
  const [registeredThisYearCount, setRegisteredThisYearCount] =
    useState<number>(0);
  const [osStats, setOsStats] = useState<any>({});

  useEffect(() => {
    const forFilteringData = usersData?.data;

    const anonUsers = forFilteringData?.filter(
      (user: any) => user.role === "ANON"
    );
    setAnonUserCount(anonUsers?.length);

    const osStats = {
      ios: 0,
      android: 0,
    };

    forFilteringData?.forEach((item: any) => {
      const os = item.deviceOs;

      if (item.role === "ANON") {
        if (os.includes("iOS")) {
          osStats.ios++;
        }

        if (os.includes("Android")) {
          osStats.android++;
        }
      }
    });

    setOsStats(osStats);

    const thisWeekRegisteredCount = anonUsers?.filter((user: any) => {
      const date = new Date(user.registeredDate);
      const today = new Date();
      const week = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7
      );
      return date > week;
    });
    setRegisteredThisWeekCount(thisWeekRegisteredCount?.length);

    const thisMonthRegisteredCount = anonUsers?.filter((user: any) => {
      const date = new Date(user.registeredDate);
      const today = new Date();
      const month = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 30
      );
      return date > month;
    });
    setRegisteredThisMonthCount(thisMonthRegisteredCount?.length);

    const thisYearRegisteredCount = anonUsers?.filter((user: any) => {
      const date = new Date(user.registeredDate);
      const today = new Date();
      const year = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 365
      );
      return date > year;
    });
    setRegisteredThisYearCount(thisYearRegisteredCount?.length);
  }, [usersData]);

  const anonCardData = {
    id: 'anonCard',
    title: t("anonymous"),
    total: anonUserCount,
    icon: <UserIcon />,
    color: "#000",
    thisWeek: registeredThisWeekCount || -1,
    thisMonth: registeredThisMonthCount || -1,
    thisYear: registeredThisYearCount || -1,
  };

  return (
    <NumberOfUsers
      data={anonCardData}
      appleStats={osStats.ios}
      androidStats={osStats.android}
    />
  );
};

export default AnonCard;
