const GreenTickIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.114}
    height={11.783}
    {...props}
  >
    <path
      data-name="Icon feather-check"
      d="m13.993 2.121-8.162 8.162-3.71-3.71"
      fill="none"
      stroke="#34c759"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    />
  </svg>
);

export default GreenTickIcon;
