import { Box, styled } from "@mui/material";

export const PhoneWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  /* background: #0000004d; */
  padding: 4px 0px;
  border-radius: 4px;
`;

export const IconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

export const IconSeparator = styled(Box)`
  width: 1px;
  height: 16px;
  background: white;
  opacity: 0.4;
`;
