const Unchecked = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27} height={27} {...props}>
    <g data-name="Icon ionic-ios-checkbox-outline">
      <path
        data-name="Path 41927"
        d="M24.75 0H2.25A2.248 2.248 0 0 0 0 2.25v22.5A2.248 2.248 0 0 0 2.25 27h22.5A2.248 2.248 0 0 0 27 24.75V2.25A2.248 2.248 0 0 0 24.75 0Zm.281 24.469a.564.564 0 0 1-.562.563H2.531a.564.564 0 0 1-.562-.562V2.531a.564.564 0 0 1 .563-.562h21.937a.564.564 0 0 1 .563.563Z"
        fill="#c2c2c2"
      />
    </g>
  </svg>
);

export default Unchecked;
