import { createAsyncThunk } from '@reduxjs/toolkit';
import { City } from '../../interfaces/general';
import { HttpRequest, RequestType } from '../../plugins/httpRequest';

export const getCities = createAsyncThunk(
  'cities',
  async ({ countryId }: { countryId: string }) => {
    const result = HttpRequest<null, City[]>({
      url: 'Client/List',
      method: RequestType.GET,
      params: { type: 'city', countryId },
    });
    return result;
  }
);
