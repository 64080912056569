/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Box, alpha } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout";
import LoadingSpinner from "../../components/loadingSpinner";
import { IMG_URL } from "../../constants";
import { deleteCard, getCards } from "../../services/cardsSlice";
import { setCategoryId } from "../../services/categoriesSlice";
import { useAppDispatch, useAppSelector } from "../../services";
import { useAuth } from "../../utils/useAuth";
import ContentCard from "./components/contentCard";
import { CardsWrapper, Header } from "./styles";
import { Card } from "../../interfaces/card";
import { StatusType } from "../../interfaces/general";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from 'react-i18next';

export type CardFilterType = {
  status: StatusType;
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(TextField)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Cards = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [cardsFilter, setCardsFilter] = useState<CardFilterType>({
    status: StatusType.ALL,
  });
  const [search, setSearch] = useState("");
  const [platform, setPlatform] = useState("BOTH");
  const { token } = useAuth() as any;
  let [page, setPage] = useState(1);

  const { categories, categoryId } = useAppSelector(
    (state) => state.categories
  );
  const { countryId } = useAppSelector((state) => state.countries);
  const { cards, loading } = useAppSelector((state) => state.cards);
  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(
      getCards({
        page,
        categoryId,
        platform,
        status: cardsFilter.status,
        search,
        user,
      })
    );
  }, [page, user]);

  useEffect(() => {
    setPage(1);
    dispatch(
      getCards({
        page: 1,
        categoryId,
        platform,
        status: cardsFilter.status,
        search,
        user,
      })
    );
  }, [categoryId, platform, countryId, cardsFilter.status, user]);

  useEffect(() => {
    clearTimeout(ref.current);
    ref.current = setTimeout(() => {
      dispatch(
        getCards({
          page,
          categoryId,
          platform,
          status: cardsFilter.status,
          search,
          user,
        })
      );
    }, 500);
  }, [search, user]);

  const count = Math.ceil(cards.length / 8); // => length / per_page

  const handleChange = (e: any, page: number) => {
    /* const nextSkip = (page - 1) * 8;
    dispatch(setSkip(nextSkip)); */
    setPage(page);
  };

  const handleDeleteCard = (id: string) => {
    dispatch(deleteCard({ id, token })).then(() =>
      dispatch(getCards({ page }))
    );
  };
  const ref = useRef<any>();

  const { t } = useTranslation(['cards']);

  return (
    <Layout>
      <Header>
        <Box flex={"1"} flexGrow={1}>
          <Typography variant="paragraph">Filters: </Typography>
          <FormControl sx={{ width: "150px", ml: "16px" }}>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={categoryId}
              onChange={(e: any) => dispatch(setCategoryId(e.target.value))}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ height: "36px", fontSize: "14px" }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
            >
              <MenuItem value="">{t('all-categories')}</MenuItem>
              {categories?.data?.map((category: any, index: number) => {
                if (!category) return null;
                return (
                  <MenuItem key={category.id} value={category.id}>
                    {category.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "150px", ml: "16px" }}>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={cardsFilter.status}
              onChange={(e: any) => setCardsFilter({ status: e.target.value })}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ height: "36px", fontSize: "14px" }}
            >
              <MenuItem value={StatusType.ALL}>{t('all-cards')}</MenuItem>
              <MenuItem value={StatusType.ACTIVE}>{t('active-card')}</MenuItem>
              <MenuItem value={StatusType.PASSIVE}>{t('passive-card')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: "150px", ml: "16px" }}>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={platform}
              onChange={(e: any) => setPlatform(e.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ height: "36px", fontSize: "14px" }}
            >
              <MenuItem value="BOTH">{t('all-platforms')}</MenuItem>
              <MenuItem value="WEB">Web</MenuItem>
              <MenuItem value="MOBILE">Mobile</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: "150px", ml: "16px" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon color="disabled" />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={t('search')}
                inputProps={{ "aria-label": "search" }}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Search>
          </FormControl>
        </Box>
        <Button variant="contained" onClick={() => navigate("/cards/addCard")}>
          <Typography variant="buttonText">{t('add-new-card')}</Typography>
        </Button>
      </Header>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "32px",
        }}
      >
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          showFirstButton
          showLastButton
        />
      </Box>
      <CardsWrapper>
        {loading ? (
          <LoadingSpinner height={30} />
        ) : cards?.length > 0 ? (
          cards.data
            ?.filter((card) => {
              if (cardsFilter.status === StatusType.ALL) {
                return card;
              }
              return card.status === cardsFilter.status;
            })
            .map((item: Card, index: number) => {
              const cardIndex =
                page > 1 ? index + 1 + (page - 1) * 8 : index + 1;

              return (
                <ContentCard
                  // imgsrc={`https://picsum.photos/450/340?random=${page}`}
                  // imgsrc={`https://source.unsplash.com/random/450x340?sig=${cardIndex}`}
                  // imgsrc={`https://svaypprodbucket.s3.eu-central-1.amazonaws.com/${item.media}`}
                  imgsrc={IMG_URL + item.media}
                  cardIndex={cardIndex}
                  key={`card-${cardIndex}`}
                  active={item.status === "ACTIVE"}
                  title={item.title}
                  data={item}
                  handleDeleteCard={handleDeleteCard}
                />
              );
            })
        ) : (
          //! TODO: request another component design from the designer
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
            }}
          >
            There is no card in this category!
          </Box>
        )}
      </CardsWrapper>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
          mb: "70px",
        }}
      >
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          showFirstButton
          showLastButton
        />
      </Box>
    </Layout>
  );
};

export default Cards;
