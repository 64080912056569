import { FC, ReactElement } from 'react';
import {
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  Box,
} from '@mui/material';

export interface Column<T> {
  id: string;
  label: string | ReactElement<any, any>;
  minWidth?: number | string;
  width?: number | string;
  align?: 'right';
  format?: (value: number) => string;
  render?: (item: T, index: number) => any;
}

type CustomTableProps = {
  title: string;
  data: any[];
  columns: Column<any>[];
  noDataMessage?: string;
};

const CustomTable: FC<CustomTableProps> = ({
  title,
  data,
  columns,
  noDataMessage,
}) => {
  return (
    <TableContainer
      sx={{ maxHeight: 'calc(100vh - 150px)', mt: '20px' }}
      component={Paper}
    >
      <Box
        sx={{
          color: '#000',
          fontSize: '16px',
          margin: '16px',
          ml: '24px',
        }}
      >
        {title}
      </Box>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            {columns?.map((column, _idx) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width,
                  paddingLeft: _idx === 0 ? '24px' : '0px',
                  textAlign: _idx === 0 ? 'start' : 'center',
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data ? (
            data?.map((row, idx: number) => {
              return (
                <TableRow key={row?.id}>
                  {columns?.map((column, idx: number) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={Math.random() + column.id}
                        align={column.align}
                        style={{
                          paddingLeft: idx === 0 ? '24px' : '0px',
                          textAlign: idx === 0 ? 'start' : 'center',
                        }}
                      >
                        {column.render ? column.render(row, idx) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <TableRow>{noDataMessage}</TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
