import { createSlice } from '@reduxjs/toolkit';
import { City } from '../../interfaces/general';
import { getCities } from './api';

export interface CategoriesState {
  cities: City[];
  loading: boolean;
  error: any;
}

const initialState: CategoriesState = {
  cities: [],
  loading: true,
  error: '',
};

export const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCities.pending, (state) => {
      state.loading = true;
      state.error = '';
    });

    builder.addCase(getCities.fulfilled, (state, action) => {
      state.cities = action.payload;
      state.loading = false;
    });

    builder.addCase(getCities.rejected, (state) => {
      state.loading = false;
      state.error = 'Error fetching cities data!';
    });
  },
});

export default citiesSlice.reducer;
