import {
  Box,
  Button,
  inputLabelClasses,
  Modal,
  OutlinedInputProps,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import AddImageIcon from "../../../../assets/categories/AddImageIcon";
import CloseIcon from "../../../../assets/categories/CloseIcon";
import SwitchComp from "../../../../components/switchComp";
import { HttpRequest } from "../../../../plugins/httpRequest";
import { useAppDispatch, useAppSelector } from "../../../../services";
import {
  createCategory,
  updateCategories,
  deleteCategory as deleteCategoryAction,
} from "../../../../services/categoriesSlice/api";
import { RequestType } from "../../../../plugins/httpRequest";
import { Category } from "../../../../interfaces/category";
import { IMG_URL } from "../../../../constants";

type CategoryModalProps = {
  open: boolean;
  onClose: any;
  categoryImg: string;
  setCategoryImg: any;
  setCropperModal: any;
  croppedImgSrc: any;
  category?: Category;
};

const CategoryModal: FC<CategoryModalProps> = ({
  open,
  onClose,
  categoryImg,
  setCategoryImg,
  setCropperModal,
  croppedImgSrc,
  category,
}) => {
  const [checked, setChecked] = useState(false);
  const imageRef = useRef();

  const [categoryName, setCategoryName] = useState(category?.title);
  const [categoryPoint, setCategoryPoint] = useState(
    category?.defaultCardPoint
  );
  useEffect(() => {
    setCategoryName(category?.title);
    setCategoryPoint(category?.defaultCardPoint);
    setChecked(category?.status === "ACTIVE");
  }, [category]);
  const dispatch = useAppDispatch();
  const { countryId } = useAppSelector((state) => state.countries);

  const handleFileUpload = (e: any) => {
    setCropperModal(true);
    const media = e.target.files[0];
    setCategoryImg(media);
  };

  useEffect(() => {
    setCategoryImg(null);
  }, [open, setCategoryImg]);

  const checkCategoryField = () => {
    if (!categoryImg && !category?.media) return false;
    if (!categoryName) return false;
    if (!(categoryPoint! >= 0)) return false;

    if (!croppedImgSrc.startsWith("data") && !category?.media) return false;
    return true;
  };


  const handleDeleteCategory = async (categoryId: string) => {
    dispatch(deleteCategoryAction( categoryId ));
  };

  const saveCategory = async () => {
    if (checkCategoryField()) {
      const formData = new FormData();
      formData.append("extension", "jpg");
      formData.append("content", croppedImgSrc);
      let imageRes;
      if (croppedImgSrc.startsWith("data")) {
        imageRes = await HttpRequest<any, { fileName: string }>({
          url: "Admin/Upload/Base64",
          method: RequestType.POST,
          body: formData,
          isFormData: true,
        });
      }


      if (category) {
        dispatch(
          updateCategories({
            category: {
              id: category.id,
              defaultCardPoint: Number(categoryPoint) | 0,
              media: imageRes ? imageRes?.fileName : category.media,
              title: categoryName!,
              countryId,
              status: checked ? "ACTIVE" : "PASSIVE",
              updatedById: "e48cba54-fa3d-4530-bf03-5dc308080c2f",
            },
          })
        )
          .then(() => {})
          .finally(() => {
            onClose();
          });
      } else {
        dispatch(
          createCategory({
            category: {
              defaultCardPoint: Number(categoryPoint) | 0,
              media: imageRes?.fileName!,
              title: categoryName!,
              countryId,
              status: checked ? "ACTIVE" : "PASSIVE",
              updatedById: "e48cba54-fa3d-4530-bf03-5dc308080c2f",
            },
          })
        )
          .then(() => {})
          .finally(() => {
            onClose();
          });
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "591px",
          height: category ? "360px" : "320px",
          bgcolor: "background.paper",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          onClick={onClose}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            cursor: "pointer",
            padding: "12px 16px",
          }}
        >
          <CloseIcon />
        </Box>
        <Typography variant="paragraph" color="#000">
          Add Category
        </Typography>
        <Typography variant="paragraph" m="16px 0px">
          Please type a name and upload a for category.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          }}
        >
          <input
            // @ts-ignore
            ref={imageRef}
            type="file"
            accept="image/*,.svg+xml"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          {!categoryImg && !category ? (
            <AddImageIcon
              onClick={() => {
                // @ts-ignore
                imageRef.current.click();
              }}
            />
          ) : (
            <Box
              sx={{
                width: "122px",
                height: "122px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              component="img"
              src={
                croppedImgSrc
                  ? croppedImgSrc || categoryImg
                  : IMG_URL + category?.media
              }
              onClick={() => {
                // @ts-ignore
                imageRef.current.click();
              }}
            />
          )}
          <Box
            sx={{
              flex: 1,
              flexDirection: "column",
              height: "155px",
              gap: "16px",
            }}
          >
            <TextField
              sx={{
                width: "100%",
                mb: "16px",
              }}
              id="categoryName"
              label="Category name"
              variant="filled"
              onChange={(e) => setCategoryName(e.target.value)}
              value={categoryName}
              InputProps={
                {
                  disableUnderline: true,
                } as Partial<OutlinedInputProps>
              }
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#c2c2c2",
                  },
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TextField
                id="categoryPoints"
                label="Category points"
                variant="filled"
                // type={"number"}
                // onChange={(e) => setEmail(e.target.value)}
                onChange={(e) => setCategoryPoint(Number(e.target.value))}
                value={categoryPoint || 0}
                InputProps={
                  {
                    disableUnderline: true,
                  } as Partial<OutlinedInputProps>
                }
                InputLabelProps={{
                  sx: {
                    [`&.${inputLabelClasses.shrink}`]: {
                      color: "#c2c2c2",
                    },
                  },
                }}
              />
             

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Typography variant="paragraph">
                  {checked ? "Active" : "Passive"}
                </Typography>
                <SwitchComp checked={checked} setChecked={setChecked} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
        <Button variant="contained" onClick={saveCategory} sx={{width: "100%"}}>
          <Typography variant="buttonText">
            {category ? "Update" : "Save"}
          </Typography>
        </Button>
        {
            category &&   
            <Button
            onClick={() => handleDeleteCategory(category.id)}
              variant="contained"
              sx={{
                width: "100%",
                marginTop: "8px",
                backgroundColor: "#f44336",

                "&:hover": {
                  backgroundColor: "#f44336",
                },
              }}
            >
              <Typography variant="buttonText">Delete Category</Typography>
            </Button>
        
         }
        </Box>
      </Box>
    </Modal>
  );
};

export default CategoryModal;
