const AnalyticsIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={40}
    height={40}
    {...props}
  >
    <path
      fill={props.primarycolor ? "#ff9f27" : "#21252D"}
      d="M0 0H40V40H0z"
    ></path>
    <g
      fill={props.primarycolor ? "#fff" : "#778095"}
      clipPath="url(#clip0_209_137)"
    >
      <path
        d="M17.6 12.8v5.866L12.92 21.6a8.405 8.405 0 01.846-5.338c.858-1.614 2.217-2.841 3.834-3.462z"
        opacity="0.2"
      ></path>
      <path d="M17.846 19.108a.614.614 0 00.308-.533v-5.537a.614.614 0 00-.82-.577 8.004 8.004 0 00-5.2 8.998.615.615 0 00.912.42l4.8-2.771zm-.923-5.138v4.25l-3.683 2.124c-.01-.116-.01-.233-.01-.347a6.774 6.774 0 013.693-6.027zM26.95 16.04l-.022-.043c-.008-.013-.015-.025-.024-.037A8.003 8.003 0 0020 12a.615.615 0 00-.615.615v7.062l-6.063 3.53a.616.616 0 00-.22.845A8 8 0 0028 19.997a7.944 7.944 0 00-1.05-3.958zm-6.334-2.78a6.775 6.775 0 014.915 2.84l-4.915 2.861v-5.702zM20 26.764a6.804 6.804 0 01-5.5-2.821l5.8-3.377.018-.01 5.828-3.394A6.765 6.765 0 0120 26.765z"></path>
    </g>
  </svg>
);

export default AnalyticsIcon;
