export interface PaginationReponse<T> {
  skip: number;
  take: number;
  length: number;
  data: T[];
}

export interface City {
  countryId: string;
  type: string;
  name: string;
  code: null;
  id: string;
}

export interface RequestFilter {
  Type: "or" | "and" | "in";
  Items: RequestFilterItem[];
  Values?: string[];
  Name?: string;
}

export interface RequestFilterItem {
  Name: string;
  Value?: string;
  Values?: string[];
  Type:
    | "and"
    | "or"
    | "="
    | "<"
    | ">"
    | "<="
    | ">="
    | "<>"
    | "like"
    | "not like"
    | "in| not in"
    | "between"
    | "not between"
    | "null"
    | "not null";
}

export enum StatusType {
  "ALL" = "",
  "ACTIVE" = "ACTIVE",
  "PASSIVE" = "PASSIVE",
}
