import React from "react";
import { Box, Typography } from "@mui/material";
import SwitchComp from "../../../../components/switchComp";

type CardPlatformsProps = {
  isWeb: boolean;
  isMobile: boolean;
  setData: any;
};

const CardPlatforms: React.FC<CardPlatformsProps> = ({
  isWeb,
  isMobile,
  setData,
}) => {

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid #c2c2c2",
            borderRadius: "4px",
            height: "48px",
            padding: "0 16px",
          }}
        >
          <Typography variant="paragraph">Web</Typography>
          <SwitchComp
            checked={isWeb}
            setChecked={(isWeb: boolean) => {
              setData((prev: any) => {
                return { ...prev, isWeb };
              });
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid #c2c2c2",
            borderRadius: "4px",
            height: "48px",
            padding: "0 16px",
          }}
        >
          <Typography variant="paragraph">Mobile</Typography>
          <SwitchComp
            checked={isMobile}
            setChecked={(isMobile: boolean) => {
              setData((prev: any) => {
                return { ...prev, isMobile };
              });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CardPlatforms;
