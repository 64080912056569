const ShareLinkIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25.711}
    height={12.778}
    {...props}
  >
    <g
      data-name="Icon feather-link-2"
      fill="none"
      stroke="#5a5a5a"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path
        data-name="Path 41350"
        d="M16.089 1h3.233a5.389 5.389 0 1 1 0 10.778h-3.233m-6.467 0H6.389A5.389 5.389 0 1 1 6.389 1h3.233"
      />
      <path data-name="Path 41351" d="M8.545 6.389h8.622" />
    </g>
  </svg>
);

export default ShareLinkIcon;
