import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

type DrawerListItemProps = {
  route: string;
  title: string;
  subtitle?: string;
  drawerOpen: boolean;
  icon?: any;
  setDrawerOpen?: any;
};

const DrawerListItem: FC<DrawerListItemProps> = ({
  route,
  title,
  subtitle,
  drawerOpen,
  icon,
  setDrawerOpen,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation(['layout']);

  const handleClick = () => {
    if (title === t("collapse-menu")) {
      setDrawerOpen();
      return;
    }
    navigate(route);
  };
  return (
    <ListItem
      key={title}
      disablePadding
      sx={{ display: "block" }}
      onClick={handleClick}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: drawerOpen ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemText
          primary={title}
          secondary={subtitle}
          sx={{ opacity: drawerOpen ? 1 : 0 }}
          primaryTypographyProps={{
            textTransform: "none",
            color: "#C2C2C2",
          }}
          secondaryTypographyProps={{
            textTransform: "none",
            color: "#C2C2C280",
          }}
        />
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: drawerOpen ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {icon}
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  );
};

export default DrawerListItem;
