import { Box, styled } from "@mui/material";

export const LayoutWrapper = styled(Box)`
  background: #f3f3f3;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;

  padding: 0 16px;
`;

export const BottomTextWrapper = styled(Box)`
  position: absolute;
  bottom: 0;
  border-top: 0.5px solid #c2c2c2;
  padding: 12px 0px;
  width: calc(100% - 32px);
`;

export const Breadcrumbs = styled(Box)`
  margin-top: 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  a{
    color: #5E5E5E;
    text-decoration: none;
    font-size: 14px;
  }
  p{
    color: #FF9F27;
    font-size: 14px;
  }
`;

export const VersionBox = styled(Box)`
  position: absolute;
  top: 55%;
  transform: translateY(-55%);
  right: 18px;
  padding: 4px 6px;
  border-radius: 999px;
  text-align: right;
  font-size: 10px;
  color: #C2C2C2;
  background: #21252d;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.35s ease-in-out;
  &:hover{
    opacity: 1;
  }
`;

