import React from "react";
import { Box, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CardDateTimePicker from "../dateTimePicker";
import { useTranslation } from 'react-i18next';

type CardDatePickersProps = {
  startDate: string;
  endDate: string;
  setData: any;
};

const CardDatePickers: React.FC<CardDatePickersProps> = ({
  startDate,
  endDate,
  setData,
}) => {

  const { t } = useTranslation(["cards"]);

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <Typography variant="paragraph" color="#C2C2C2" mb="8px">
      {t('date-info')}
      </Typography>

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
          <CardDateTimePicker
            label={t('start-date')}
            value={startDate}
            onChange={(startDate: any) => {
              setData((prev: any) => {
                return { ...prev, startDate };
              });
            }}
          />
          <CardDateTimePicker
            label={t('end-date')}
            value={endDate}
            onChange={(endDate: any) => {
              setData((prev: any) => {
                return { ...prev, endDate };
              });
            }}
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );
};

export default CardDatePickers;
