const TinyArrowIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={6.006}
    height={9.003}
    {...props}
  >
    <path
      data-name="Arrow copy 13"
      d="M2.813 4.502-.002 7.35l1.633 1.654 4.372-4.427-.075-.075.074-.075L1.634-.004-.002 1.654Z"
      fill="#fff"
      opacity={0.902}
    />
  </svg>
);

export default TinyArrowIcon;
