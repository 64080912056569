import { Box, styled } from "@mui/material";

export const Wrapper = styled(Box)`
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;

  width: 360px;
  height: 640px;
`;


export const CardImageWrapper = styled(Box)`
  width: 100%;
  height: 640px;
  position: relative;
  overflow: hidden;
`;

export const CardImage = styled(Box)<{ imgsrc: string }>`
  /* background: ${(props) => `url(${props.imgsrc})`}; */
  background: ${(props) =>
    `url(${props.imgsrc})`};
  background-size: 100% 100%;

  position: absolute;
  bottom: 0;

  width: 100%;
  height: 640px;
  background-position: center;
  background-size: cover;

  padding: 81px 35px 35px 35px;
`;
