import { Box, styled } from "@mui/material";

export const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 145px;
  padding: 16px;
`;
