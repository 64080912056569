const LeftArrowIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27.228}
    height={27.228}
    {...props}
  >
    <g
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      data-name="vuesax/twotone/arrow-up"
    >
      <path
        d="M13.614 2.374a11.241 11.241 0 1 1-11.241 11.24 11.241 11.241 0 0 1 11.241-11.24Z"
        stroke={props.color ? props.color : "#fff"}
      />
      <g data-name="Group 645" stroke="#ed0505">
        <path
          data-name="Vector"
          d="M17.767 13.614h-7.119M13.021 17.174l-3.56-3.56 3.56-3.56"
        />
      </g>
    </g>
  </svg>
);

export default LeftArrowIcon;
