import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../constants";
import {
  PaginationReponse,
  RequestFilter,
  RequestFilterItem,
  StatusType,
} from "../../interfaces/general";
import { HttpRequest, RequestType } from "../../plugins/httpRequest";
import { Card } from "../../interfaces/card";
import { RootState } from "..";

export const getCards = createAsyncThunk(
  "getCards",
  async (
    {
      page,
      categoryId,
      platform,
      status,
      search,
      user,
    }: {
      page: number;
      categoryId?: string;
      platform?: string;
      status?: StatusType;
      search?: string;
      user?: any;
    },
    { rejectWithValue, getState }
  ) => {
    const state = getState() as RootState;
    const items: RequestFilterItem[] = [
      {
        Name: "releaseType",
        Value: platform || "BOTH",
        Type: "=",
      },
      {
        Name: "countryId",
        Value: state.countries.countryId,
        Type: "=",
      },
    ];


    if (categoryId) {
      items.push({
        Name: "categoryId",
        Value: categoryId!,
        Type: "=",
      });
    }
    if (search) {
      items.push({ Name: "title", Value: "%" + search + "%", Type: "like" });
    }

    if (status === StatusType.ACTIVE || status === StatusType.PASSIVE) {
      items.push({
        Name: "status",
        Value: status,
        Type: "=",
      });
    }

    const myValues: any = [];
    if (user.role === "EDITOR") {
      user.managedCategories?.forEach((category: any) => {
       myValues.push(
        category
       );
      });
    }
    
 const getMyBody = () => {
  if(user.role === "SUPERADMIN"){
    const myBody: RequestFilter = {
      Type: "and",
      Items: items,
    };
    return myBody;
  }else if (user.role === "ADMIN") {
    const myBody: RequestFilter = {
      Type: "and",
      Items: items,
    };
    return myBody;
  }else if (user.role === "EDITOR") {
    const myBody: RequestFilter = {
      Type: "in",
      Items: items,
      Values: myValues,
      Name: "categoryTitle"
    };
    return myBody;
  }
  }


    try {
      const result = await HttpRequest<RequestFilter, PaginationReponse<Card>>({
        url: `Admin/CardsView/GetAll`,
        method: RequestType.POST,
        body: getMyBody(),
        params: {
          skip: (page - 1) * 8,
          take: 8,
          orderBy: "createdAt desc",
        },
      });
      return user.role && result;

    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postCard = createAsyncThunk(
  "postCard",
  async (
    { body, token }: { body: any; token: string },
    { rejectWithValue }
  ) => {
    const myHeaders = new Headers({
      Authorization: token,
      "Content-Type": "application/json",
    });

    try {
      const response = await fetch(`${API_URL}Admin/Cards/Add`, {
        headers: myHeaders,
        method: "POST",
        body: JSON.stringify(body),
      });

      if (response?.status !== 200) {
        // eslint-disable-next-line
        throw "New exception";
      }

      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCard = createAsyncThunk(
  "updateCard",
  async ({ body }: { body: Partial<Card> }, { rejectWithValue }) => {
    try {
      const result = await HttpRequest<Partial<Card>, Card>({
        url: `Admin/Cards/Update`,
        method: RequestType.POST,
        body: body,
      });
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCard = createAsyncThunk(
  "deleteCard",
  async ({ id, token }: { id: string; token: string }, { rejectWithValue }) => {
    const myHeaders = new Headers({
      Authorization: token,
      "Content-Type": "application/json",
    });

    try {
      const response = await fetch(`${API_URL}Admin/Cards/Delete?id=${id}`, {
        headers: myHeaders,
        method: "POST",
      });

      if (response?.status !== 200) {
        // eslint-disable-next-line
        throw "New exception";
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
