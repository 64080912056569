const AddImageIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={121} height={122} {...props}>
    <ellipse
      data-name="Ellipse 989"
      cx={60.5}
      cy={61}
      rx={60.5}
      ry={61}
      fill="#ebebeb"
    />
    <g
      data-name="Icon feather-image"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        data-name="Path 41328"
        d="M50 45h21a3 3 0 0 1 3 3v21a3 3 0 0 1-3 3H50a3 3 0 0 1-3-3V48a3 3 0 0 1 3-3Z"
      />
      <path
        data-name="Path 41329"
        d="M57.5 53.25A2.25 2.25 0 1 1 55.25 51a2.25 2.25 0 0 1 2.25 2.25Z"
      />
      <path data-name="Path 41330" d="m74 63-7.5-7.5L50 72" />
    </g>
  </svg>
);

export default AddImageIcon;
