const UserIcon = (props: any) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 28.667 29.444"
      {...props}
    >
      <g
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        data-name="Group 29638"
        transform="translate(15943.333 -1183.667)"
      >
        <path
          d="M-15920.813 1211.604a14.87 14.87 0 01-4.187.507h-8a14.87 14.87 0 01-4.187-.507c.293-3.467 3.853-6.2 8.187-6.2s7.893 2.733 8.187 6.2z"
          opacity="0.4"
        ></path>
        <path
          d="M-15915.666 1194.272v8.233c0 5.187-1.52 8.027-5.147 9.084a14.472 14.472 0 01-4.187.521h-8a14.472 14.472 0 01-4.187-.521c-3.626-1.056-5.146-3.897-5.146-9.084v-8.233c0-6.861 2.667-9.605 9.333-9.605h8c6.667 0 9.334 2.744 9.334 9.605z"
          data-name="Vector"
        ></path>
        <path
          d="M-15924.226 1196.559a4.773 4.773 0 11-4.774-4.773 4.78 4.78 0 014.774 4.773z"
          data-name="Vector"
          opacity="0.4"
        ></path>
      </g>
    </svg>
);

export default UserIcon;
