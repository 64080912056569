import { useState, useEffect } from "react";
import NumberOfUsers from "../../../components/dashboardCards/numberOfUsers/index";
import UserIcon from "../../../assets/dashboard/UserIcon";
import { useTranslation } from "react-i18next";

const EnhancedProfileCard = ({ usersData }: any) => {
  const { t } = useTranslation(["dashboard"]);

  const [enhancedUserCount, setEnhancedUserCount] = useState<number>(0);
  const [registeredThisWeekCount, setRegisteredThisWeekCount] =
    useState<number>(0);
  const [registeredThisMonthCount, setRegisteredThisMonthCount] =
    useState<number>(0);
  const [registeredThisYearCount, setRegisteredThisYearCount] =
    useState<number>(0);
  const [osStats, setOsStats] = useState<any>({});

  useEffect(() => {
    const forFilteringData = usersData?.data;

    const enhancedUsers = forFilteringData?.filter(
      (user: any) => user.level === 2
    );
    setEnhancedUserCount(enhancedUsers?.length);

    const osStats = {
      ios: 0,
      android: 0,
    };

    forFilteringData?.forEach((item: any) => {
      const os = item.deviceOs;

      if (item.level === 2) {
        if (os.includes("iOS")) {
          osStats.ios++;
        }

        if (os.includes("Android")) {
          osStats.android++;
        }
      }
    });

    setOsStats(osStats);

    const thisWeekRegisteredCount = enhancedUsers?.filter((user: any) => {
      const date = new Date(user.registeredDate);
      const today = new Date();
      const week = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7
      );
      return date > week;
    });
    setRegisteredThisWeekCount(thisWeekRegisteredCount?.length);

    const thisMonthRegisteredCount = enhancedUsers?.filter((user: any) => {
      const date = new Date(user.registeredDate);
      const today = new Date();
      const month = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 30
      );
      return date > month;
    });
    setRegisteredThisMonthCount(thisMonthRegisteredCount?.length);

    const thisYearRegisteredCount = enhancedUsers?.filter((user: any) => {
      const date = new Date(user.registeredDate);
      const today = new Date();
      const year = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 365
      );
      return date > year;
    });
    setRegisteredThisYearCount(thisYearRegisteredCount?.length);
  }, [usersData]);

  const enhancedProfileCardData = {
    id: "enhancedProfileCard",
    title: t("enhanced-profile"),
    total: enhancedUserCount,
    icon: <UserIcon />,
    color: "#0076FF",
    thisWeek: registeredThisWeekCount || -1,
    thisMonth: registeredThisMonthCount || -1,
    thisYear: registeredThisYearCount || -1,
  };

  return (
    <NumberOfUsers
      data={enhancedProfileCardData}
      bgcolor="#0076FF"
      appleStats={osStats.ios}
      androidStats={osStats.android}
    />
  );
};

export default EnhancedProfileCard;
