import { createSlice } from "@reduxjs/toolkit";
import { addOption, getOptions } from "./api";
export { getOptions };

export interface OptionsState {
  options: any;
  loading: boolean;
  error: any;
}

const initialState: OptionsState = {
  options: [],
  loading: true,
  error: "",
};

export const optionsSlice = createSlice({
  name: "options",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOptions.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getOptions.fulfilled, (state, action: any) => {
        state.options = action.payload.data;
        state.loading = false;
      })
      .addCase(getOptions.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching cards data!";
      });

    builder
      .addCase(addOption.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(addOption.fulfilled, (state, action) => {
        state.options.push(action.payload);
        state.loading = false;
      })
      .addCase(addOption.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching cards data!";
      });
  },
});

// export const {} = optionsSlice.actions;

export default optionsSlice.reducer;
