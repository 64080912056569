const ArchivesMenuIcon = (props: any) => (
  <svg
    data-name="Component 45 \u2013 65"
    xmlns="http://www.w3.org/2000/svg"
    width={46}
    height={26}
    {...props}
  >
    <rect
      data-name="Rectangle 662"
      width={46}
      height={26}
      rx={13}
      fill="#fff"
    />
    <g
      data-name="Group 28786"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={5}
    >
      <path d="M31.98 13h.021" opacity={0.34} />
      <path
        data-name="Vector"
        d="M22.991 13h.021M14.001 13h.021"
        opacity={0.34}
      />
    </g>
  </svg>
);

export default ArchivesMenuIcon;
