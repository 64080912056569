import {
  inputLabelClasses,
  OutlinedInputProps,
  TextField,
} from "@mui/material";
import React from "react";

type CustomTextFiledProps = {
  id: string;
  name: string;
  label: string;
  value: any;
  onChange: any;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  disabled?: boolean;
};

const CustomTextFiled: React.FC<CustomTextFiledProps> = ({
  id,
  name,
  label,
  value,
  onChange,
  multiline = false,
  minRows,
  maxRows,
  disabled,
}) => {
  return (
    <TextField
      sx={{ flex: 1 }}
      id={id}
      name={name}
      label={label}
      variant="filled"
      value={value}
      onChange={onChange}
      multiline={multiline}
      minRows={minRows}
      maxRows={maxRows}
      disabled={disabled}
      InputProps={
        {
          disableUnderline: true,
        } as Partial<OutlinedInputProps>
      }
      InputLabelProps={{
        sx: {
          [`&.${inputLabelClasses.shrink}`]: {
            color: "#c2c2c2",
          },
        },
      }}
    />
  );
};

export default CustomTextFiled;
