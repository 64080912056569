import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../../components/layout";
import SwitchComp from "../../../../components/switchComp";
import { postCard, updateCard } from "../../../../services/cardsSlice";
import { useAppDispatch, useAppSelector } from "../../../../services";
import { useAuth } from "../../../../utils/useAuth";

import { API_URL } from "../../../../constants";
import moment from "moment";
import TagsInput from "../tagsInput";
import CardImage from "../cardImage";
import CardDatePickers from "../cardDatePickers";
import CardPlatforms from "../cardPlatforms";
import CardOptions from "../cardOptions";
import CardPoints from "../cardPoints";
import CardTitleDescription from "../cardTitleDescription";
import CustomTextFiled from "../../../../components/customTextField";
import { getCategories } from "../../../../services/categoriesSlice";
import { StatusType } from "../../../../interfaces/general";
import { Card } from "../../../../interfaces/card";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export type dataType = {
  id: string;
  title: string;
  description: string;
  category: string;
  cardPoint: number;
  tags: string[];
  cardType: string;
  leftOption: string;
  rightOption: string;
  topOption: any;
  startDate: any;
  endDate: any;
  source: string;
  message: string;
  isWeb: boolean;
  isMobile: boolean;
  isNotification: boolean;
  croppedImgSrc: string;
  countryId: string;
  languageId: string;
  status: "ACTIVE" | "PASSIVE";
  isPublic: boolean;
};

const AddCard = () => {
  const navigate = useNavigate();
  const location = useLocation() as { state: { data: Card } };
  const dispatch = useAppDispatch();
  const { t } = useTranslation("cards");
  const { token } = useAuth() as any;
  const { categories } = useAppSelector((state) => state.categories);
  const { countryId } = useAppSelector((state) => state.countries);
  const { languageId } = useAppSelector((state) => state.languages);
  const user = useAppSelector((state) => state.auth.user);

  const [showTopOption, setShowTopOption] = useState(
    location?.state?.data ? true : false
  );
  const [showCustomColor, setShowCustomColor] = useState(false);
  const [showDate, setShowDate] = useState(
    location?.state?.data ? true : false
  );
  const [showSource, setShowSource] = useState(
    location?.state?.data?.source ? true : false
  );
  const [showPlatforms, setShowPlatforms] = useState(
    location?.state?.data?.releaseType ? true : false
  );
  // eslint-disable-next-line
  const [showNotifications, setShowNotifications] = useState(
    location?.state?.data?.sendNotification ? true : false
  );

  const [data, _setData] = useState<dataType>({
    id: "",
    title: "",
    description: "",
    category: "",
    cardPoint: 0,
    tags: [],
    cardType: "NEWS",
    leftOption: "",
    rightOption: "",
    topOption: "",
    startDate: "",
    endDate: "",
    source: "",
    message: "",
    isWeb: true,
    isMobile: true,
    isNotification: false,
    croppedImgSrc: "",
    countryId: "",
    languageId: "",
    status: "ACTIVE",
    isPublic: true,
  });

  const setData = (e: any) => {
    const { name, value } = e.target;
    _setData({ ...data, [name]: value });
  };
  useEffect(() => {
    dispatch(getCategories({ getAll: true, status: StatusType.ACTIVE, user }));
    // eslint-disable-next-line
  }, [countryId, user]);

  useEffect(() => {
    if (location?.state?.data) {
      const {
        id,
        title,
        description,
        categoryId,
        tags,
        type,
        optionLeftId,
        optionRightId,
        optionTopId,
        releaseStartDate,
        releaseEndDate,
        source,
        countryId,
        media,
        status,
        point,
        isPublic,
      } = location.state.data;
      _setData({
        ...data,
        id,
        title,
        isPublic,
        description,
        category: categoryId,
        tags,
        cardType: type,
        leftOption: optionLeftId,
        rightOption: optionRightId,
        topOption: optionTopId,
        startDate: releaseStartDate,
        endDate: releaseEndDate,
        source,
        countryId,
        croppedImgSrc: media,
        status,
        cardPoint: point,
      });
    }
    // eslint-disable-next-line
  }, [location]);

  const setReleaseType = () => {
    const { isWeb, isMobile } = data;
    if (isWeb && isMobile) {
      return "BOTH";
    } else if (isWeb) {
      return "WEB";
    } else if (isMobile) {
      return "MOBILE";
    }
    return "";
  };

  const handleSaveCard = async () => {
    const {
      title,
      description,
      category,
      tags,
      cardType,
      leftOption,
      rightOption,
      topOption,
      startDate,
      endDate,
      source,
      isNotification,
      croppedImgSrc,
      cardPoint,
    } = data;

    const formData = new FormData();
    formData.append("extension", "jpg");
    formData.append("content", croppedImgSrc);
    const imageRes = await fetch(`${API_URL}Admin/Upload/Base64`, {
      method: "POST",
      body: formData,
    });
    const uploadedImgUrl = await imageRes?.json();
    console.log(
      "date:",
      moment(data.startDate).toISOString() ||
        moment(new Date()).toISOString(true)
    );
    const body = {
      media: uploadedImgUrl?.fileName || "",
      title,
      description,
      categoryId: category,
      point: Number(cardPoint),
      tags,
      type: cardType,
      optionLeftId: leftOption,
      optionRightId: rightOption,
      optionTopId: topOption,
      releaseStartDate:
        moment(startDate).toISOString() || moment(new Date()).toISOString(true),
      releaseEndDate: moment(endDate).toISOString(),
      source,
      releaseType: setReleaseType(),
      sendNotification: isNotification,
      countryId,
      languageId,
      updatedById: user?.id || "e48cba54-fa3d-4530-bf03-5dc308080c2f",
    };

    if (!body.title) {
      toast.error(t("title-error") as string);
    } else if (!body.categoryId) {
      toast.error(t("category-error") as string);
    } else if (!body.optionLeftId) {
      toast.error(t("left-option-error") as string);
    } else if (!body.optionRightId) {
      toast.error(t("right-option-error") as string);
    } else if (!body.media) {
      toast.error(t("image-error") as string);
    } else {
      dispatch(postCard({ body, token }))
        .unwrap()
        .then(() => navigate("/cards"))
        .catch((error: any) => console.error("post card rejected", error));
    }
  };

  const handleUpdateCard = async () => {
    const {
      id,
      title,
      description,
      category,
      tags,
      cardType,
      leftOption,
      rightOption,
      topOption,
      startDate,
      endDate,
      source,
      isNotification,
      croppedImgSrc,
      status,
      isPublic,
    } = data;

    let uploadedImgUrl: any = "";
    if (croppedImgSrc.startsWith("data")) {
      const formData = new FormData();
      formData.append("extension", "jpg");
      formData.append("content", croppedImgSrc);
      const imageRes = await fetch(`${API_URL}Admin/Upload/Base64`, {
        method: "POST",
        body: formData,
      });
      uploadedImgUrl = await imageRes?.json();
    }

    const body = {
      id,
      title,
      description,
      categoryId: category,
      point: 0,
      tags,
      type: cardType,
      optionLeftId: leftOption,
      optionRightId: rightOption,
      optionTopId: topOption,
      releaseStartDate: moment(startDate).toISOString(),
      releaseEndDate: moment(endDate).toISOString(),
      source,
      releaseType: setReleaseType(),
      sendNotification: isNotification,
      countryId,
      languageId,
      media: uploadedImgUrl?.fileName || croppedImgSrc,
      status,
      isPublic,
      updatedById: user?.id || "e48cba54-fa3d-4530-bf03-5dc308080c2f",
    };
    console.log(body);

    dispatch(
      updateCard({
        body,
      })
    );
  };

  return (
    <Layout>
      <Box
        sx={{
          minWidth: "1090px",
          alignSelf: "center",
          mt: "64px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "24px",
          mb: "80px",
        }}
      >
        <CardImage showTopOption={showTopOption} data={data} setData={_setData} />
        <Box
          sx={{
            width: "590px",
            background: "#fff",
            borderRadius: "4px",
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Typography variant="paragraph" color="#000">
            {t("new-card")}
          </Typography>

          {/* zorunlu */}
          <CardTitleDescription
            title={data.title}
            description={data.description}
            setData={setData}
          />

          <FormControl variant="filled">
            {/* zorunlu */}

            <InputLabel id="demo-simple-select-filled-label">
              {t("category")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              name="category"
              value={data.category}
              onChange={setData}
              disableUnderline
              MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
            >
              {categories?.data?.map((category: any) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <CardPoints
            categoryPoint={
              categories?.data?.find(
                (category) => data.category === category.id
              )?.defaultCardPoint || 0
            }
            cardPoint={data.cardPoint}
            setData={setData}
          />

          <TagsInput
            tags={data.tags}
            title={data.title}
            setTags={(tags: string[]) => _setData({ ...data, tags })}
          />

          <FormControl variant="filled">
            <InputLabel id="demo-simple-select-filled-label">
              {t("card-type")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              name="cardType"
              value={data.cardType}
              onChange={setData}
              disableUnderline
            >
              {/* <MenuItem value="">Card Type</MenuItem> */}
              <MenuItem value="NEWS">Haber</MenuItem>
              <MenuItem value="UNBIASED">Tarafsız</MenuItem>
              <MenuItem value="UNBIASED">Soru Cevap</MenuItem>
              <MenuItem value="UNBIASED">Bilgi</MenuItem>
              <MenuItem value="SPONSORED">Sponsorlu</MenuItem>
              <MenuItem value="PROMOTION">Promosyon</MenuItem>
              <MenuItem value="AD">Reklam</MenuItem>
              <MenuItem value="CAMPAIGN">Kampanya</MenuItem>
            </Select>
          </FormControl>
          <Divider sx={{ my: 2 }} />
          <CardOptions
            leftOption={data.leftOption}
            rightOption={data.rightOption}
            topOption={data.topOption}
            showTopOption={showTopOption}
            setData={_setData}
          />

          <Box
            sx={{
              display: "flex",
              flex: 1,
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid #c2c2c2",
                borderRadius: "4px",
                height: "48px",
                padding: "0 16px",
                opacity: showTopOption ? 1 : 0.5,
              }}
            >
              <Typography variant="paragraph"> Üst Oyu Özelleştir</Typography>
              <SwitchComp
                checked={showTopOption}
                setChecked={(showTopOption: boolean) => {
                  setShowTopOption(showTopOption);
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid #c2c2c2",
                borderRadius: "4px",
                height: "48px",
                padding: "0 16px",
                opacity: showCustomColor ? 1 : 0.5,
              }}
            >
              <Typography variant="paragraph">
                {" "}
                Özel Kart Rengi Kullan
              </Typography>
              <SwitchComp
                checked={showCustomColor}
                setChecked={(showCustomColor: boolean) => {
                  setShowCustomColor(showCustomColor);
                }}
              />
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />
          <Box sx={{ marginBottom: "12px" }}>
            <Typography variant="paragraph" color="#FF9F27" mr="8px">
              Ek Ayarlar
            </Typography>
            <Typography variant="paragraph">
              Tarih, kaynak, platform ve bildirim ayarlarıni özelleştirin.
            </Typography>
          </Box>

          <Accordion
            elevation={0}
            sx={{
              boxShadow: "none",
              border: "none",
              borderRadius: "4px",
              padding: "0",
              margin: "0",
              "&:before": { display: "none" },
              "&.Mui-expanded": { margin: "0" },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid #c2c2c292",
                borderRadius: "4px",
                height: "48px",
                padding: "0 16px",
              }}
            >
              Ek Ayarlar
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "12px 0 0 0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #c2c2c2",
                    borderRadius: "4px",
                    height: "48px",
                    padding: "0 16px",
                    opacity: showDate ? 1 : 0.5,
                  }}
                >
                  <Typography variant="paragraph">
                    Bu kart için aktiflik tarhini seçin
                  </Typography>
                  <SwitchComp
                    checked={showDate}
                    setChecked={(showDate: boolean) => {
                      setShowDate(showDate);
                    }}
                  />
                </Box>
              </Box>
              {showDate && (
                <CardDatePickers
                  startDate={data.startDate}
                  endDate={data.endDate}
                  setData={_setData}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #c2c2c2",
                    borderRadius: "4px",
                    height: "48px",
                    padding: "0 16px",
                    opacity: showSource ? 1 : 0.5,
                  }}
                >
                  <Typography variant="paragraph">{t("referral")}</Typography>
                  <SwitchComp
                    checked={showSource}
                    setChecked={(showSource: boolean) => {
                      setShowSource(showSource);
                    }}
                  />
                </Box>
              </Box>

              {showSource && (
                <CustomTextFiled
                  id="source"
                  name="source"
                  label={t("source")}
                  value={data.source}
                  onChange={setData}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #c2c2c2",
                    borderRadius: "4px",
                    height: "48px",
                    padding: "0 16px",
                    opacity: showPlatforms ? 1 : 0.5,
                  }}
                >
                  <Typography variant="paragraph">{t("platforms")}</Typography>
                  <SwitchComp
                    checked={showPlatforms}
                    setChecked={(showPlatforms: boolean) => {
                      setShowPlatforms(showPlatforms);
                    }}
                  />
                </Box>
              </Box>
              {showPlatforms && (
                <CardPlatforms
                  isWeb={data.isWeb}
                  isMobile={data.isMobile}
                  setData={_setData}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #c2c2c2",
                    borderRadius: "4px",
                    height: "48px",
                    padding: "0 16px",
                    opacity: showPlatforms ? 1 : 0.5,
                  }}
                >
                  <Typography variant="paragraph" color="#FF9F27" mr="8px">
                    {t("notifications")}
                  </Typography>
                  <Typography variant="paragraph" sx={{ flex: 1 }}>
                    {t("notifications-info")}
                  </Typography>
                  <SwitchComp
                    disabled
                    checked={false}
                    setChecked={(showPlatforms: boolean) => {
                      setShowPlatforms(showPlatforms);
                    }}
                  />
                </Box>
              </Box>

              {showNotifications && (
                <CustomTextFiled
                  id="message"
                  name="message"
                  label="Message"
                  value={data.message}
                  onChange={setData}
                />
              )}
            </AccordionDetails>
          </Accordion>
          <Button
            onClick={
              location?.state?.data?.id ? handleUpdateCard : handleSaveCard
            }
          >
            <Typography variant="buttonText">
              {location?.state?.data?.id ? t("update") : t("save")}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default AddCard;
