import { toast } from "react-toastify";
import { createSlice } from "@reduxjs/toolkit";
import { Category } from "../../interfaces/category";
import { createCategory, deleteCategory, getCategories, updateCategories } from "./api";
import { PaginationReponse } from "../../interfaces/general";
export { getCategories, updateCategories };

export interface CategoriesState {
  categories: PaginationReponse<Category>;
  categoryId: string;
  loading: boolean;
  error: any;
}

const initialState: CategoriesState = {
  categories: { data: [], length: 0, skip: 0, take: 0 },
  categoryId: "",
  loading: true,
  error: "",
};

export const categoriesSlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        if (action.payload) {
          state.categories = action.payload;
        }
        state.loading = false;
      })
      .addCase(getCategories.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching categories data!";
        toast.error("Get categories failed!");
      });

    builder
      .addCase(updateCategories.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateCategories.fulfilled, (state, action) => {
        const index = state.categories.data.findIndex(
          (category) => category.id === action.payload.id
        );
        state.categories.data[index] = action.payload;
        state.loading = false;
        toast.success("Category updated!");
        setInterval(() => {
          window.location.reload();
        }
        , 2000);
      })
      .addCase(updateCategories.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching cards data!";
      });

    builder
      .addCase(createCategory.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.categories.data.push(action.payload);
        state.loading = false;
        toast.success("Category created!");
        setInterval(() => {
          window.location.reload();
        }
        , 2000);
      })
      .addCase(createCategory.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching cards data!";
      });

    builder
      .addCase(deleteCategory.pending, (state) => {
        state.loading = true;
        state.error = "";
      }
      )
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.categories.data = state.categories.data.filter(
          (category: any) => category.id !== action.payload
        );
        state.loading = false;
        toast.warning("Category deleted!");
        setInterval(() => {
          window.location.reload();
        }
        , 2000);
      }
      )
      .addCase(deleteCategory.rejected, (state) => {
        state.loading = false;
        state.error = "Error fetching cards data!";
      }
      );


  },
});

export const { setCategoryId } = categoriesSlice.actions;

export default categoriesSlice.reducer;
