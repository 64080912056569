import { FC, ReactElement } from "react";
import {
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Box,
  Typography,
} from "@mui/material";
import MeatballsMenuIcon from "../../../../assets/cards/MeatballsMenuIcon";
import RightArrowIcon from "../../../../assets/categories/RightArrowIcon";
import LeftArrowIcon from "../../../../assets/categories/LeftArrowIcon";
import UpArrowIcon from "../../../../assets/categories/UpArrowIcon";
import CloseIcon from "../../../../assets/categories/CloseIcon";
import { DynamicCard } from "../../../../interfaces/dynamic";
import moment from 'moment';

export interface Column<T> {
  id: string;
  label: string | ReactElement<any, any>;
  minWidth?: number | string;
  width?: number | string;
  align?: "right";
  format?: (value: number) => string;
  render?: (item: T, index: number) => any;
}

type CustomAnalyticsTableProps = {
  data: any[];
  setSelectedData?: any;
  isSelected?: boolean;
};
/* 
"cardTitle": "Prof. İlhan: \"Rakamlar 1500'e uzanır diye endişem var. Gelecek hafta maalesef yine binlerin üzerinde seyreder.\"",
            "role": "ADMIN",
            "level": null,
            "count": 7,
            "optionleftstat": 3,
            "optionrightstat": 4,
            "optiontopstat": 0 */
const CustomAnalyticsTable: FC<CustomAnalyticsTableProps> = ({
  data,
  setSelectedData,
  isSelected,
}) => {
  const TinyTableCell = (value: number | string, index: number) => {
    return (
      <TableCell
        key={Math.random() + index}
        sx={{
          width: "100%",
          borderWidth: "1.5px",
          textAlign: "center",
          cursor: "pointer",
          color: value === "Anonim" || value === "Onaylanmış Profil" || value === "Gelişmiş Profil" ? "#fff" : "#000",
          backgroundColor:
          value === "Anonim"
            ? "#000"
            : value === "Onaylanmış Profil"
            ? "#34c759"
            : value === "Gelişmiş Profil"
            ? "#0076FF"
            : null,
        }}
      >
        {value}
      </TableCell>
    );
  };
  // console.log("data", data);
  const columns: Column<DynamicCard>[] = [
    {
      id: "date",
      label: "DATE",
      width: "10%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {
            localStorage.getItem('i18nextLng') === 'tr' ? moment(item?.createdAt).format('DD.MM.YYYY') : moment(item?.createdAt).format('MM.DD.YYYY')
          }
        </Typography>
      ),
    },
    {
      id: "title",
      label: "TITLE",
      width: "25%",
      render: (item) => (
        <Typography variant="paragraph" color="#000">
          {item.cardTitle}
        </Typography>
      ),
    },
    {
      id: "userType",
      label: "USER TYPE",
      width: "15%",
      render: (item) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {["Anonim", "Onaylanmış Profil", "Gelişmiş Profil"].map(
            TinyTableCell
          )}
        </Box>
      ),
    },
    {
      id: "count",
      label: "TOTAL VOTE",
      width: "10%",
      render: (item) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {item.dynamics
            .map((x) => {
              const totalCount = item.dynamics.reduce((prev, i) => {
                return prev + i.count;
              }, 0);
              return `${((x.count / totalCount) * 100).toFixed(1)}% (${
                x.count
              })`;
            })
            .map(TinyTableCell)}
        </Box>
      ),
    },
    {
      id: "positive",
      label: "OLUMLU",
      width: "10%",
      render: (item) => {
        // console.log(
        //   item.dynamics.reduce((prev, curr) => prev + curr.optionleftstat, 0)
        // );
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {item.dynamics
              .map((x, index) => {
                const indexDynamic = item.dynamics[index];
                const { optionrightstat, optionleftstat, optiontopstat } =
                  indexDynamic;
                const totalCount =
                  optionleftstat + optiontopstat + optionrightstat;
                // console.log("totalCount", totalCount);
                return `${((x.optionleftstat / totalCount) * 100 || 0).toFixed(
                  1
                )}% (${x.optionleftstat})`;
              })
              .map(TinyTableCell)}
          </Box>
        );
      },
    },
    {
      id: "negative",
      label: "OLUMSUZ",
      width: "10%",
      render: (item) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {item.dynamics
            .map((x, index) => {
              const indexDynamic = item.dynamics[index];
              const { optionrightstat, optionleftstat, optiontopstat } =
                indexDynamic;
              const totalCount =
                optionleftstat + optiontopstat + optionrightstat;
              // console.log("totalCount", totalCount);
              return `${((x.optionrightstat / totalCount) * 100 || 0).toFixed(
                1
              )}% (${x.optionrightstat})`;
            })
            .map(TinyTableCell)}
        </Box>
      ),
    },
    {
      id: "indecisive",
      label: "KARARSIZ",
      width: "10%",
      render: (item) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {item.dynamics
            .map((x, index) => {
              const indexDynamic = item.dynamics[index];
              const { optionrightstat, optionleftstat, optiontopstat } =
                indexDynamic;
              const totalCount =
                optionleftstat + optiontopstat + optionrightstat;
              // console.log("totalCount", totalCount);
              return `${((x.optiontopstat / totalCount) * 100 || 0).toFixed(
                1
              )}% (${x.optiontopstat})`;
            })
            .map(TinyTableCell)}
        </Box>
      ),
    },
    {
      id: "detail",
      label: "",
      width: "10%",
      render: (item) => {
        return isSelected ? (
          <Box onClick={() => setSelectedData(null)}>
            <CloseIcon color="#000" />
          </Box>
        ) : (
          <MeatballsMenuIcon />
        );
      },
    },
  ];
  return (
    <TableContainer sx={{ background: "#fff", mb: "0px" }} component={Box}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column, _idx) => (
              <TableCell
                key={Math.random() + column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width,
                  paddingLeft: _idx === 0 ? "24px" : "0px",
                  textAlign: _idx === 0 ? "start" : "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "12px",
                  }}
                >
                  {column.id === "positive" && <RightArrowIcon color="#000" />}
                  {column.id === "negative" && <LeftArrowIcon color="#000" />}
                  {column.id === "indecisive" && <UpArrowIcon color="#000" />}
                  {column.label}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, idx: number) => {
            return (
              <TableRow key={row?.id}>
                {columns.map((column, idx: number) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={Math.random() + column.id}
                      onClick={() => !isSelected && setSelectedData(row)}
                      align={column.align}
                      style={{
                        textAlign: "center",
                        padding: "0px",
                        border: "1px solid #C2C2C2",
                      }}
                    >
                      {column.render ? column.render(row, idx) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomAnalyticsTable;
