const DashboardIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <path
      fill={props.primarycolor ? "#ff9f27" : "#21252D"}
      d="M0 0H40V40H0z"
    ></path>
    <g
      stroke={props.primarycolor ? "#fff" : "#778095"}
      clipPath="url(#clip0_209_131)"
    >
      <path
        fill={props.primarycolor ? "#fff" : "#778095"}
        d="M13.333 16c0-1.257 0-1.886.39-2.276.391-.39 1.02-.39 2.277-.39 1.257 0 1.886 0 2.276.39.39.39.39 1.02.39 2.276v1.334c0 1.257 0 1.885-.39 2.276-.39.39-1.019.39-2.276.39s-1.886 0-2.276-.39c-.39-.39-.39-1.02-.39-2.276V16zM21.333 22.667c0-1.257 0-1.886.39-2.277C22.115 20 22.744 20 24 20c1.257 0 1.886 0 2.276.39.39.391.39 1.02.39 2.277V24c0 1.257 0 1.886-.39 2.276-.39.39-1.019.39-2.276.39s-1.886 0-2.276-.39c-.39-.39-.39-1.019-.39-2.276v-1.333z"
        opacity="0.4"
      ></path>
      <path
        fill="none"
        strokeWidth="1.5"
        d="M13.333 16c0-1.257 0-1.886.39-2.276.391-.39 1.02-.39 2.277-.39 1.257 0 1.886 0 2.276.39.39.39.39 1.02.39 2.276v1.334c0 1.257 0 1.885-.39 2.276-.39.39-1.019.39-2.276.39s-1.886 0-2.276-.39c-.39-.39-.39-1.02-.39-2.276V16zM13.333 24.666c0-.62 0-.931.102-1.176.135-.327.395-.587.721-.722.245-.102.556-.102 1.177-.102h1.334c.62 0 .931 0 1.176.102.327.135.587.395.722.722.102.245.102.555.102 1.177 0 .62 0 .931-.102 1.176a1.333 1.333 0 01-.722.722c-.244.101-.555.101-1.176.101h-1.334c-.621 0-.932 0-1.177-.101a1.334 1.334 0 01-.721-.722c-.102-.245-.102-.555-.102-1.177zM21.333 22.667c0-1.257 0-1.886.39-2.277C22.115 20 22.744 20 24 20c1.257 0 1.886 0 2.276.39.39.391.39 1.02.39 2.277V24c0 1.257 0 1.886-.39 2.276-.39.39-1.019.39-2.276.39s-1.886 0-2.276-.39c-.39-.39-.39-1.019-.39-2.276v-1.333zM21.333 15.334c0-.622 0-.932.102-1.177.135-.327.395-.587.721-.722.245-.101.556-.101 1.177-.101h1.334c.62 0 .931 0 1.176.101.327.135.587.395.722.722.102.245.102.555.102 1.177 0 .62 0 .931-.102 1.176a1.333 1.333 0 01-.722.722c-.244.102-.555.102-1.176.102h-1.334c-.621 0-.932 0-1.177-.102a1.334 1.334 0 01-.721-.722c-.102-.245-.102-.555-.102-1.177z"
      ></path>
    </g>
  </svg>
);

export default DashboardIcon;
