const SvaypLogoLight = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={92.003}
    height={23.001}
    {...props}
  >
    <path
      d="M5.985 15.872c.246 1.342 1.8 2.122 3.642 2.122 1.952 0 3.15-.8 3.15-1.9 0-1.048-.862-1.493-3.351-1.941l-2.227-.4C2.79 12.96.378 10.738.378 7.288.378 2.819 4.218 0 9.49 0c5.93 0 9.257 2.758 9.278 7.147h-5.99c-.048-1.355-1.431-2.14-3.211-2.14s-2.737.8-2.737 1.8.954 1.48 3.17 1.881l2.23.4c4.761.865 7.006 2.819 7.006 6.426 0 4.575-3.565 7.487-9.68 7.487C3.811 23 .016 20.428 0 15.883Z"
      fill="#fff"
    />
    <path
      data-name="Vector"
      d="M24.783 22.657 18.092.55h7.456l3.531 15.948h.122L32.731.55h7.141l-6.694 22.1Z"
      fill="#5a5a5a"
    />
    <path
      data-name="Vector"
      d="M58.992 22.587v-7.05L51.407.328h6.969l3.893 9.015h.124L66.287.328h6.82l-7.59 15.209v7.05ZM83.74.328c4.938 0 8.26 3.16 8.26 8.113 0 4.905-3.523 8.068-8.709 8.068h-3.172v6.077H73.6V.328Zm-3.618 11.356h1.83c2.169 0 3.462-1.1 3.462-3.224S84.121 5.252 82 5.252h-1.881Z"
      fill="#fff"
    />
    <path
      data-name="Vector"
      d="m49.834.55 6.694 22.1H49.07L45.541 6.709h-.124L41.889 22.65h-7.141L41.431.55Z"
      fill="#c2c2c2"
    />
  </svg>
);

export default SvaypLogoLight;
