import { createSlice } from "@reduxjs/toolkit";
import { PaginationReponse } from "../../interfaces/general";
import { User } from "../../interfaces/user";
import {
  createModerator,
  deleteModerator,
  getModerators,
  updateModerator,
} from "./api";

export interface ModeratorState {
  moderators: PaginationReponse<User>;
  loading: boolean;
  error: any;
}

const initialState: ModeratorState = {
  moderators: { data: [], length: 0, skip: 0, take: 0 },
  loading: true,
  error: "",
};

export const moderatorsSlice = createSlice({
  name: "moderators",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getModerators.pending, (state) => {
      state.loading = true;
      state.error = "";
    });

    builder.addCase(getModerators.fulfilled, (state, action) => {
      state.moderators = action.payload;
      state.loading = false;
    });

    builder.addCase(getModerators.rejected, (state) => {
      state.loading = false;
      state.error = "Error fetching moderators data!";
    });
    builder
      .addCase(createModerator.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createModerator.fulfilled, (state, action) => {
        state.moderators.data = [action.payload, ...state.moderators.data];
        state.loading = false;
      })
      .addCase(createModerator.rejected, (state) => {
        state.loading = false;
        state.error = "Error creating moderator!";
      });
    builder
      .addCase(deleteModerator.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteModerator.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteModerator.rejected, (state) => {
        state.loading = false;
        state.error = "Error creating moderator!";
      });
    builder
      .addCase(updateModerator.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateModerator.fulfilled, (state, action) => {
        const index = state.moderators.data.findIndex(
          (user) => user.id === action.payload.id
        );
        state.moderators.data[index] = action.payload;
        state.loading = false;
      })
      .addCase(updateModerator.rejected, (state) => {
        state.loading = false;
        state.error = "Error creating moderator!";
      });
  },
});

export default moderatorsSlice.reducer;
