const CollapseIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <path
      data-name="40x40 Charts"
      fill={props.primarycolor ? "#ff9f27" : "#21252D"}
      d="M0 0h40v40H0z"
    />
    <g
      fill="none"
      stroke={props.primarycolor ? "#fff" : "#778095"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      data-name="vuesax/twotone/arrow-left"
    >
      <path d="M26.666 20A6.667 6.667 0 1 1 20 13.333 6.667 6.667 0 0 1 26.666 20Z" />
      <g data-name="Group 647" opacity={0.4}>
        <path data-name="Vector" d="M22.334 20h-4M19.667 18l-2 2 2 2" />
      </g>
    </g>
  </svg>
);

export default CollapseIcon;
