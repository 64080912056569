const CardsIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={40}
    height={40}
    {...props}
  >
    <path
      fill={props.primarycolor ? "#ff9f27" : "#21252D"}
      d="M0 0H40V40H0z"
    ></path>
    <path
      stroke={props.primarycolor ? "#fff" : "#77809589"}
      strokeWidth="1.5"
      d="M24.14 25.6c.876-.49 1.174-1.568 1.77-3.723l.844-3.047c.596-2.155.894-3.232.388-4.081-.506-.85-1.619-1.138-3.844-1.715l-1.574-.409c-2.226-.577-3.338-.866-4.215-.376-.624.35-.955.996-1.309 2.117"
    ></path>
    <path
      stroke={props.primarycolor ? "#fff" : "#778095"}
      strokeWidth="1.5"
      d="M13 19.2c0-2.263 0-3.394.703-4.097.703-.703 1.834-.703 4.097-.703h1.6c2.263 0 3.394 0 4.097.703.703.703.703 1.834.703 4.097v4c0 2.263 0 3.394-.703 4.097C22.794 28 21.663 28 19.4 28h-1.6c-2.263 0-3.394 0-4.097-.703C13 26.594 13 25.463 13 23.2v-4z"
    ></path>
    <path
      stroke={props.primarycolor ? "#fff" : "#778095"}
      fillRule="evenodd"
      d="M13.703 15.103C13 15.806 13 16.937 13 19.2v4c0 2.263 0 3.394.703 4.097.703.703 1.834.703 4.097.703h1.6c2.263 0 3.394 0 4.097-.703.703-.703.703-1.834.703-4.097v-4c0-2.263 0-3.394-.703-4.097-.703-.703-1.834-.703-4.097-.703h-1.6c-2.263 0-3.394 0-4.097.703z"
      clipRule="evenodd"
      opacity="0.4"
    ></path>
    <path
      fill={props.primarycolor ? "#ffffff69" : "#7780955e"}
      stroke={props.primarycolor ? "#fff" : "#778095"}
      strokeLinejoin="round"
      strokeWidth="0.6"
      d="M16.062 23.224a.3.3 0 00.288.213h1.191a.3.3 0 00.287-.214l.95-3.136a.3.3 0 00-.287-.387h-1.014a.3.3 0 00-.293.235l-.216.98-.217-.98a.3.3 0 00-.293-.235H15.4a.3.3 0 00-.287.387l.95 3.137z"
    ></path>
    <path
      stroke={props.primarycolor ? "#fff" : "#778095"}
      strokeLinejoin="round"
      strokeWidth="0.6"
      d="M21.028 19.913a.3.3 0 00-.287-.213h-1.193a.3.3 0 00-.287.213l-.948 3.136a.3.3 0 00.287.387h1.013a.3.3 0 00.293-.235l.217-.979.216.98a.3.3 0 00.293.234h1.059a.3.3 0 00.287-.387l-.95-3.136z"
    ></path>
  </svg>
);

export default CardsIcon;
