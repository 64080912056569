const ModeratorsIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    {...props}
    fill="none"
    viewBox="0 0 40 40"
  >
    <path
      fill={props.primarycolor ? "#ff9f27" : "#21252D"}
      d="M0 0H40V40H0z"
    ></path>
    <g
      fill={props.primarycolor ? "#fff" : "#778095"}
      clipPath="url(#clip0_209_136)"
    >
      <path
        d="M22.199 25.228l-1.085 1.085a.686.686 0 01-.97 0l-2.201-2.199L15.059 27a.685.685 0 01-.97 0L13 25.914a.686.686 0 010-.97l2.885-2.887-2.2-2.197a.685.685 0 010-.97l1.085-1.089a.684.684 0 01.97 0l6.454 6.454a.687.687 0 01.004.973z"
        opacity="0.2"
      ></path>
      <path d="M27.36 12h-5.12a.64.64 0 00-.507.25l-5.12 6.656-.772-.771a1.28 1.28 0 00-1.811 0l-1.015 1.016a1.28 1.28 0 000 1.81l1.6 1.6-2.24 2.24a1.279 1.279 0 000 1.81l1.015 1.014a1.28 1.28 0 001.81 0l2.24-2.24 1.6 1.6a1.28 1.28 0 001.81 0l1.015-1.015a1.28 1.28 0 000-1.81l-.77-.772 6.655-5.12a.64.64 0 00.25-.508v-5.12a.64.64 0 00-.64-.64zM14.296 26.72l-1.015-1.015 2.24-2.24 1.014 1.014-2.239 2.24zm5.649-.64l-6.024-6.024 1.016-1.016 6.024 6.024-1.016 1.015zm6.775-8.635l-6.538 5.03-.876-.876 4.027-4.027a.64.64 0 10-.905-.904L18.4 20.694l-.875-.875 5.03-6.54h4.164v4.166z"></path>
    </g>
  </svg>
);

export default ModeratorsIcon;
