const ExpandIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <path
      data-name="40x40 Charts"
      fill={props.primarycolor ? "#ff9f27" : "#21252D"}
      d="M0 0h40v40H0z"
    />
    <g
      fill="none"
      stroke={props.primarycolor ? "#fff" : "#778095"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      data-name="vuesax/twotone/arrow-left"
    >
      <path d="M13.334 20A6.667 6.667 0 1 1 20 26.667 6.667 6.667 0 0 1 13.334 20Z" />
      <g data-name="Group 647" opacity={0.4}>
        <path data-name="Vector" d="M17.666 20h4M20.333 22l2-2-2-2" />
      </g>
    </g>
  </svg>
);

export default ExpandIcon;
