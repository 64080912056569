export const clientsData = [
  {
    id: "1",
    name: "Tarkan Kaya",
    phone: "+90 555 *** ** **",
    email: "tarkankaya@svayp.com",
    registeredDate: "12.10.2020",
    finishDate: "12.10.2020",
    region: "İstanbul",
    userType: "Kategori",
    request: "CHP",
    status: "$9.90",
  },
  {
    id: "2",
    name: "Esra Tokar",
    phone: "+90 555 *** ** **",
    email: "tarkankaya@svayp.com",
    registeredDate: "12.10.2020",
    finishDate: "12.10.2020",
    region: "İstanbul",
    userType: "Bireysel",
    request: "Fenerbahçe",
    status: "$9.90",
  },
  {
    id: "3",
    name: "Ferruh Cihan",
    phone: "+90 555 *** ** **",
    email: "tarkankaya@svayp.com",
    registeredDate: "12.10.2020",
    finishDate: "12.10.2020",
    region: "Ankara",
    userType: "Bireysel",
    request: "İTÜ",
    status: "Teklif bekliyor",
  },
  {
    id: "4",
    name: "Beyza Efe",
    phone: "+90 555 *** ** **",
    email: "tarkankaya@svayp.com",
    registeredDate: "12.10.2020",
    finishDate: "12.10.2020",
    region: "Bursa",
    userType: "Bireysel",
    request: "",
    status: "Teklif bekliyor",
  },
  {
    id: "5",
    name: "Esra Tokar",
    phone: "+90 555 *** ** **",
    email: "tarkankaya@svayp.com",
    registeredDate: "12.10.2020",
    finishDate: "12.10.2020",
    region: "İstanbul",
    userType: "Kategori",
    request: "",
    status: "$9.90",
  },
];
