const TinyCircleIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={9} height={9} {...props}>
    <g
      data-name="Tiel Reload copy 2"
      transform="translate(2 2)"
      fill="rgba(255,255,255,0)"
      stroke="#f3f3f3"
      strokeLinejoin="round"
      strokeWidth={2}
      opacity={0.678}
    >
      <circle cx={2.5} cy={2.5} r={2.5} stroke="none" />
      <circle cx={2.5} cy={2.5} r={3.5} fill="none" />
    </g>
  </svg>
);

export default TinyCircleIcon;
