import { Box, styled } from "@mui/material";

export const DashboardWrapper = styled(Box)`
  display: flex;
  flex: 1;
`;

export const LeftSection = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export const RightSection = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 10px;
`;

export const CardsWrapper = styled(Box)`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  gap: 15px;
  
  @media screen and (max-width: 900px){
    height: 100%;
  }
`;

export const MapWrapper = styled(Box)`
  width: 100%;
  height: 600px;

  margin-top: 32px;
  margin-bottom: 60px;


`;
