
import { createSlice } from '@reduxjs/toolkit';
import { getUsersByCountry } from './api';



const initialState = {
  dashboard: { data: [], length: 0, skip: 0, take: 0 },
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(getUsersByCountry.fulfilled, (state, action) => {
        state.dashboard = action.payload;
      }
      );
    },
});

export default dashboardSlice.reducer;