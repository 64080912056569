import { useTranslation } from 'react-i18next';
const AddCardImageIcon = (props: any) => {
  const { t } = useTranslation('cards');
 return (
 <svg xmlns="http://www.w3.org/2000/svg" width={161} height={61.5} {...props}>
    <g data-name="Group 28843">
      <text
        data-name="CLICK TO SELECT IMAGE"
        transform="translate(0 56.5)"
        fontSize={14}
        fontFamily="Poppins-Regular, Poppins"
        letterSpacing=".005em"
      >
        <tspan x={0} y={0}>
          {t('select-image')}
        </tspan>
      </text>
      <g
        data-name="Icon feather-image"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          data-name="Path 41328"
          d="M70 .5h21a3 3 0 0 1 3 3v21a3 3 0 0 1-3 3H70a3 3 0 0 1-3-3v-21a3 3 0 0 1 3-3Z"
        />
        <path
          data-name="Path 41329"
          d="M77.5 8.75a2.25 2.25 0 1 1-2.25-2.25 2.25 2.25 0 0 1 2.25 2.25Z"
        />
        <path data-name="Path 41330" d="M94 18.5 86.5 11 70 27.5" />
      </g>
    </g>
  </svg>
 )
};

export default AddCardImageIcon;
