export const activityLogsData = [
  {
    id: "1",
    name: "Tarkan Kaya",
    datetime: "12/18/20 09:21:02",
    duration: "8s",
    executable: "Google Chrome",
    region: "İstanbul",
    cardCount: "100",
    userType: "Super Admin",
  },
  {
    id: "2",
    name: "Esra Tokar",
    datetime: "12/18/20 09:21:02",
    duration: "22s",
    executable: "System Event",
    region: "Sakarya",
    cardCount: "50",
    userType: "Admin",
  },
  {
    id: "3",
    name: "Ferruh Cihan",
    datetime: "12/18/20 09:21:02",
    duration: "80s",
    executable: "Google Chrome",
    region: "Ankara",
    cardCount: "70",
    userType: "Admin",
  },
  {
    id: "4",
    name: "Behran Kankul",
    datetime: "12/18/20 09:21:02",
    duration: "55s",
    executable: "Safari",
    region: "İstanbul",
    cardCount: "100",
    userType: "Super Admin",
  },
  {
    id: "5",
    name: "Beyza Efe",
    datetime: "12/18/20 09:21:02",
    duration: "55s",
    executable: "Yandex",
    region: "Balıkesir",
    cardCount: "100",
    userType: "Admin",
  },
  {
    id: "6",
    name: "John Doe",
    datetime: "12/18/20 09:21:02",
    duration: "44s",
    executable: "Opera",
    region: "Kahramanmaraş",
    cardCount: "10",
    userType: "Admin",
  },
];
