const RightArrowIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27.004}
    height={27.004}
    {...props}
  >
    <g
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      data-name="vuesax/twotone/arrow-up"
    >
      <path
        d="M13.502 24.612a11.109 11.109 0 1 1 11.109-11.11 11.109 11.109 0 0 1-11.109 11.11Z"
        stroke={props.color ? props.color : "#fff"}
      />
      <g data-name="Group 645" stroke="#34c759">
        <path
          data-name="Vector"
          d="M9.315 13.502h7.178M14.1 9.913l3.589 3.589-3.589 3.589"
        />
      </g>
    </g>
  </svg>
);

export default RightArrowIcon;
