import { Box, styled, TableRow } from "@mui/material";

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;

  /* padding: 0 32px; */
  width: 100%;
`;

export const TitleWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  color: #5a5a5a;
  border-bottom: 1.25px solid #5a5a5a;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  margin-bottom: 8px;
  padding-bottom: 4px;
`;

export const Title = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Mute = styled(Box)`
  cursor: pointer;
  margin-right: 8px;
`;

export const GreenDot = styled(Box)`
  border-radius: 50%;
  border: 2px solid #34c759;
  width: 4px;
  height: 4px;
  position: relative;
  animation: pulse 2s infinite;
`;

export const Dot = styled(Box)`
  border-radius: 50%;
  background-color: #5a5a5a;
  width: 6px;
  height: 6px;
`;

export const LineItem = styled(TableRow)`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #5a5a5aba;
  align-items: center;
  padding: 8px 0px;
  gap: 24px;
`;

export const LineText = styled(TableRow)`
  font-size: 8px;
  color: #5a5a5a;
  font-weight: 500;
  min-width: 20px;
  :first-of-type {
    width: 10%;
  }
  :nth-of-type(2) {
    width: 70%;
  }
  :nth-of-type(3) {
    width: 10%;
  }
  :nth-of-type(4) {
    width: 10%;
  }
`;
