const ArchivesIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={40}
    height={40}
    {...props}
  >
    <path
      fill={props.primarycolor ? "#ff9f27" : "#21252D"}
      d="M0 0H40V40H0z"
    ></path>
    <path
      fill={props.primarycolor ? "#fff" : "#778095"}
      d="M26.4 17.6v6.646a.54.54 0 01-.17.392.597.597 0 01-.412.162H14.182a.597.597 0 01-.412-.162.54.54 0 01-.17-.392V17.6h12.8z"
      opacity="0.2"
    ></path>
    <path
      fill={props.primarycolor ? "#fff" : "#778095"}
      d="M26.857 14.4H13.143c-.303 0-.594.118-.808.328a1.11 1.11 0 00-.335.792v1.68c0 .297.12.582.335.792.214.21.505.328.808.328v6.16c0 .297.12.582.335.792.214.21.505.328.808.328h11.428c.303 0 .594-.118.808-.328a1.11 1.11 0 00.335-.792v-6.16c.303 0 .594-.118.808-.328A1.11 1.11 0 0028 17.2v-1.68c0-.297-.12-.582-.335-.792a1.155 1.155 0 00-.808-.328zm-1.143 10.08H14.286v-6.16h11.428v6.16zm1.143-7.28H13.143v-1.68h13.714v1.68zm-9.143 3.36c0-.149.06-.291.168-.396a.577.577 0 01.404-.164h3.428c.152 0 .297.059.404.164a.554.554 0 010 .792.577.577 0 01-.404.164h-3.428a.577.577 0 01-.404-.164.554.554 0 01-.168-.396z"
    ></path>
  </svg>
);

export default ArchivesIcon;
