import { Box, styled } from "@mui/material";

export const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 120px;
`;

export const CardsWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  width: 100%;
  padding: 20px;
  
  @media (max-width: 2400px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (max-width: 2000px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 1780px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1460px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 940px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 620px) {
    grid-template-columns: repeat(1, 1fr);
  }
  `;
