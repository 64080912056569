import React, { useEffect, useState } from "react";
import { Wrapper, Title, Dot, GreenDot, LineItem, LineText, TitleWrapper, Mute } from "./styles";
import VoteArrow from "../../assets/dashboard/VoteArrow";
import { API_URL, WEB_SOCKET_URL } from "../../constants";
import { useTranslation } from "react-i18next";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { Tooltip } from "@mui/material";
import Skeleton from "react-loading-skeleton";

interface VoteData {
  userId: string;
  level: number;
  role: string;
  cardId: string;
  optionId: string;
  votePosition: string;
  cityName: string;
  countryName: string;
}

const LiveVotes = () => {
  const { t } = useTranslation(["dashboard"]);

  const [votes, setVotes] = useState<VoteData[] | null>(null);
  const [newVoteIndex, setNewVoteIndex] = useState<number | null>(null);
  const [userInteracted, setUserInteracted] = useState<boolean>(false);
  const notificationSound = new Audio('notification.wav');
  notificationSound.volume = 0.1;

  const handleButtonClick = () => {
    setUserInteracted(!userInteracted);
  };

  useEffect(() => {
    return () => {
      notificationSound.pause();
      notificationSound.currentTime = 0;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchVotes = async () => {
    const token = JSON.parse(window.localStorage.getItem("token") || "");
    fetch(API_URL + "Admin/VotesView/GetAll?take=20&orderBy=createdAt desc", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setVotes(data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  
  useEffect(() => {
    if (!votes) {
      fetchVotes();
    }

    const ws = new window.WebSocket(WEB_SOCKET_URL);

    ws.onopen = () => {
      setInterval(() => {
        ws.send("Socket ping.");
      }, 60000);
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data) as VoteData;
      setNewVoteIndex(0);
      setVotes((prevVotes: any) => {
        const updatedVotes = [data, ...prevVotes];
        if (userInteracted) {
          notificationSound.play();
        }
        return updatedVotes;
      });

      setTimeout(() => {
        setNewVoteIndex(null);
      }, 3000);
    };

    ws.onclose = () => {
      // console.log('WebSocket connection closed.');
    };

    return () => {
      ws.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInteracted]);

  return (
    <Wrapper>
      <TitleWrapper>
       <Title>
       <GreenDot style={{ borderWidth: "4px" }} />
        {t("live-choices")}
      </Title>
      <Tooltip title={userInteracted ? "Canlı oy seslerini kapat" : "Canlı oy seslerini aç"} placement="top">
      <Mute>
        {userInteracted ? <VolumeUpIcon onClick={handleButtonClick} sx={{fontSize: 16}} /> : <VolumeOffIcon onClick={handleButtonClick}  sx={{fontSize: 16}}  />}
      </Mute>
      </Tooltip>
      </TitleWrapper>
     {
      votes ?  votes?.slice(0, 20).map((item: VoteData, index: number) => (
        <LineItem key={index} style={index >= votes.slice(0, 20).length - 3 ? { opacity: 0.75 + 0.25 * (votes.slice(0, 20).length - 3 - index) } : {}}>
          <LineText className={index === newVoteIndex ? "new-vote-animation" : ""}>TR</LineText>
          <LineText className={index === newVoteIndex ? "new-vote-animation" : ""}>{item?.userId?.replace(/^(.{6}).*(.{6})$/, "$1-***************-$2")}</LineText>
          <LineText>
            {item?.votePosition === "top" && <VoteArrow className={index === newVoteIndex ? "new-vote-animation" : ""} />}
            {item?.votePosition === "right" && <VoteArrow className={index === newVoteIndex ? "new-vote-animation" : ""} style={{ rotate: "90deg", fill: "#34c759" }} />}
            {item?.votePosition === "left" && <VoteArrow className={index === newVoteIndex ? "new-vote-animation" : ""} style={{ rotate: "-90deg", fill: "#ed0505" }} />}
          </LineText>
          <LineText>
            {item?.level === 0 && <Dot className={index === newVoteIndex ? "new-vote-animation" : ""} style={{ backgroundColor: "#000" }} />}
            {item?.level === 1 && <Dot className={index === newVoteIndex ? "new-vote-animation" : ""} style={{ backgroundColor: "#34c759" }} />}
            {item?.level === 2 && <Dot className={index === newVoteIndex ? "new-vote-animation" : ""} style={{ backgroundColor: "#0076FF" }} />}
          </LineText>
          {/* <LineText>{new Date(item.createdAt).toLocaleString('tr-TR', {dateStyle: 'short', timeStyle: 'short', hour12: false})}</LineText> */}
        </LineItem>
      ))
      : <>
     <Skeleton height={32} count={20} baseColor="#d1d1d1" />
      </>
     }
    </Wrapper>
  );
};

export default LiveVotes;
